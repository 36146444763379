export enum MenuList {
  'GISInfo' = 1,
  'Dashboard' = 2,
  'Dashboard DTR' = 3,
  'Dashboard PTR' = 4,
  'Data Report' = 5,
  'Meter Data' = 6,
  'DTR Prediction Analysis' = 7,
  'Analytics' = 8,
  'Load & Temp Graph' = 9,
  'Load' = 10,
  'Voltage Graph' = 11,
  'Current Graph' = 12,
  'Settings' = 13,
  'Threshold Settings' = 14,
  'Alerts Settings' = 15,
  'Devices' = 16,
  'Alert' = 17,
  'System Battery' = 18,
  'Downloads' = 19,
  'Software' = 20,
  'Settings History' = 21,
  'Office Use' = 22,
  'Overload' = 23,
  'PF' = 24,
  'CAIDI (DTR)' = 25,
  'Sent-Notification' = 26,
  'Status' = 27,
  'Office Report' = 28,
  'DTRs Data' = 29,
  'Changes' = 30,
  'PTR Loads' = 32,
  'PTR Interruption' = 33,
  'SS SLD Dashboard' = 34,
  'Roles' = 35,
  'Page Permission' = 36,
  'Feeder' = 37,
  'OTAStatus' = 38,
  'Complaints' = 42,
  'Substation' = 43,
  'Account List' = 44,
  'Dashboard Device Status' = 44,
  'UserDevicesList' = 200,
  'Module-History' = 45,
}
