import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core'
import { Router } from '@angular/router'
import {
  ActionTypeEnum,
  DataReportColumnColor,
  DataTypeEnum,
  FeederType,
  MenuList,
  OfficeTypeListEmun,
  TicketStatusEnum,
} from '@app/enums'
import {
  ColumnMessagesforGrid,
  NotificationMessage,
} from '@app/enums/notification'
import { Roles } from '@app/enums/roles'
import { CommonService } from '@app/services'
import { CheckIfDbExistsForDevice, CommonState, SetDeviceId } from '@app/store'
import { Select, StateToken, Store } from '@ngxs/store'
import { DialogService } from '@progress/kendo-angular-dialog'
import {
  MultipleSortSettings,
  PageChangeEvent,
  PagerPosition,
  PagerType,
} from '@progress/kendo-angular-grid'
import {
  CompositeFilterDescriptor,
  SortDescriptor,
} from '@progress/kendo-data-query'
import { ToastrService } from 'ngx-toastr'
import { Observable } from 'rxjs'
import swal from 'sweetalert'
import { CommonColumnSelectorComponent } from '../common-column-selector/common-column-selector.component'
import { DatePipe } from '@angular/common'

@Component({
  selector: 'app-common-grid',
  templateUrl: './common-grid.component.html',
  styleUrl: './common-grid.component.scss',
})
export class CommonGridComponent implements OnInit {
  @Output()
  readonly triggerActionEvent = new EventEmitter<any>()
  @Input() showActions: boolean = false
  @Input() actions: any[] = []
  @Input() gridView: any
  @Input() columns: any[] = []
  @Input() gridHeight: number = 0
  @Input() isPaginationAllowed: boolean = true
  @Input() isSortingAllowed: boolean = true
  @Input() pageSize = 10
  @Input() selectedOfficeTypeList: any

  @Output() sortChange = new EventEmitter<any>()
  @Output() paginationChange = new EventEmitter<any>()
  @Output() deleteConfirmed = new EventEmitter<any>()
  @Output() triggerComplaintEdit = new EventEmitter<any>()
  @Output() triggerDataFromColumnSelector = new EventEmitter<any>()
  @Output() filterChangeSQL = new EventEmitter<any>()

  dataType = DataTypeEnum
  @Input()
  moduleId: number = 0
  @Input() triggerToClearAllFilter: Observable<any> | undefined
  public filter: CompositeFilterDescriptor = {
    logic: 'and',
    filters: [],
  }
  module = MenuList
  roles = Roles
  @Select(CommonState.hasEditPermission)
  hasEditPermission$!: Observable<boolean>

  @Select(CommonState.hasDeletePermission)
  hasDeletePermission$!: Observable<boolean>

  actionTypeEnum = ActionTypeEnum
  //#region pagination
  type: PagerType = 'numeric'
  buttonCount = 5
  info = true
  pageSizes = [5, 10, 20, 50, 100]
  previousNext = true
  pagerposition: PagerPosition = 'bottom'
  skip = 0
  sorting = null
  multiple = false
  allowUnsort = true
  //#endregion

  //#region Sorting
  sort: SortDescriptor[] = []

  sortSettings: MultipleSortSettings = {
    mode: 'multiple',
    initialDirection: 'desc',
    allowUnsort: true,
    showIndexes: true,
  }
  //#endregion

  noColumnMessage: string | undefined
  noColumnDetailMessage: string | undefined
  showCustomizeColumn: boolean = false

  constructor(
    private toastr: ToastrService,
    private store: Store,
    private router: Router,
    private commonService: CommonService,
    private dialogService: DialogService,
    private datePipe: DatePipe,
  ) {}

  ngOnInit(): void {
    this.sort = this.columns.map((column) => {
      if (!column.hasColumn.length) {
        return { field: column.field }
      } else {
        return column.hasColumn.map((subColumn: any) => {
          return { field: subColumn.field }
        })
      }
    })

    this.calculateGridHeight()

    window.addEventListener('resize', () => {
      this.calculateGridHeight()
    })

    this.triggerToClearAllFilter?.subscribe((res) => {
      if (res) {
        this.filter = { logic: 'and', filters: [] }
      }
    })
  }

  hasSelectedColumns(): boolean {
    if (this.gridView?.columns.length === 0) {
      this.noColumnMessage = ColumnMessagesforGrid.NoColumnsAssignedMessage
      this.noColumnDetailMessage =
        ColumnMessagesforGrid.NoColumnsAssignedDetailMessage
    } else {
      this.noColumnMessage = ColumnMessagesforGrid.NoColumnsSelectedMessage
      this.noColumnDetailMessage =
        ColumnMessagesforGrid.NoColumnsSelectedDetailMessage
      this.showCustomizeColumn = true
    }

    return this.gridView?.columns?.some((x: any) => x.isSelected === true)
  }

  noColumnAssign(): boolean {
    return this.gridView?.columns?.some((x: any) => x.isSelected === true)
  }

  calculateGridHeight(): void {
    const screenHeight = window.innerHeight
    const headerHeight = 81
    const gridHeaderHeight = 46
    const formHeaderHeight = 56
    const wrapperpadding = 20

    if (
      this.moduleId == MenuList.UserDevicesList ||
      this.moduleId == MenuList.GISInfo
    ) {
      this.gridHeight = 300
    } else {
      this.gridHeight =
        screenHeight -
        (headerHeight + gridHeaderHeight + wrapperpadding + formHeaderHeight)
    }
  }

  getClass(status: boolean): string {
    return status === false ? 'red-text' : ''
  }

  getCssClass(status: any): string {
    let statusColour = ''
    if (status === 'Pending') {
      statusColour = 'red-text'
    } else if (status === 'Updated') {
      statusColour = 'green-text'
    }
    return statusColour
  }

  getStatusText(status: TicketStatusEnum): string {
    switch (status) {
      case TicketStatusEnum.Active:
        return 'Active'
      case TicketStatusEnum.Pending:
        return 'Pending'
      case TicketStatusEnum.Resolved:
        return 'Resolved'
      default:
        return 'Unknown'
    }
  }

  getFeederType(feederType: FeederType): string {
    switch (feederType) {
      case FeederType.DTR:
        return 'DTR'
      case FeederType.PTR:
        return 'PTR'
      default:
        return 'Unknown'
    }
  }

  getConditionalClass(value: any): string {
    const conditionalClasses: { [key: string]: string } = {
      R: 'class-r',
      B: 'class-b',
      Y: 'class-y',
    }

    return conditionalClasses[value] || ''
  }

  getColourBoxClass(colurType: any): string {
    let colour = ''
    if (DataReportColumnColor.Orange === colurType) {
      colour = 'orange-box'
    } else if (DataReportColumnColor.Red === colurType) {
      colour = 'red-box'
    }
    return colour
  }

  getColorField(dataField: string): string {
    const fieldMapping: any = {
      phaseVoltageR: 'phaseVoltageColorR',
      phaseVoltageY: 'phaseVoltageColorY',
      phaseVoltageB: 'phaseVoltageColorB',
      phaseCurrentR: 'phaseCurrentColorR',
      phaseCurrentY: 'phaseCurrentColorY',
      phaseCurrentB: 'phaseCurrentColorB',
      loadFactorValue: 'loadFactorColor',
      phaseLoadFactorR: 'phaseLoadFactorColorR',
      phaseLoadFactorY: 'phaseLoadFactorColorY',
      phaseLoadFactorB: 'phaseLoadFactorColorB',
      powerFactorTotal: 'powerFactorColor',
      temperatureR: 'temperatureColorR',
      temperatureY: 'temperatureColorY',
      temperatureB: 'temperatureColorB',
      temperatureOil: 'temperatureColorOil',
    }
    return fieldMapping[dataField] || ''
  }

  onPageChange({ skip, take }: PageChangeEvent): void {
    this.skip = skip
    this.paginationChange.emit({ skip, take })
  }

  onSortChange(sort: any): void {
    this.sort = sort
    const sortData = {
      sort: [{ field: null }],
    }
    this.sortChange.emit({
      sort:
        sort[0].dir !== null && sort[0].dir !== undefined
          ? sort
          : sortData.sort,
    })
  }

  checkIfDbExistsForDevice(data: any, actionTypeId: any) {
    this.store
      .dispatch(new CheckIfDbExistsForDevice(data.deviceId))
      .subscribe((res) => {
        if (res.device.isDbExistsForDevice) {
          this.editDeleteAction(data, actionTypeId)
        } else {
          this.toastr.error('Database for this device is not yet created.')
        }
      })
  }

  editDeleteAction(data: any, actionTypeId: any) {
    if (actionTypeId === ActionTypeEnum.Delete) {
      this.confirmDelete(data)
    } else {
      const param = {
        data: data,
        actionTypeId: actionTypeId,
      }
      this.triggerActionEvent.emit(param)
    }
  }

  onActionClick(data: any, actionTypeId: any): void {
    if (
      this.moduleId === MenuList.Devices &&
      actionTypeId === ActionTypeEnum.Edit
    ) {
      this.checkIfDbExistsForDevice(data, actionTypeId)
    } else {
      this.editDeleteAction(data, actionTypeId)
    }
  }

  confirmDelete(data: any) {
    let textMessage = ''

    if (this.moduleId === MenuList.Devices) {
      textMessage = `You want to delete ${data.deviceName}.`
    } else if (this.moduleId === MenuList.Roles) {
      textMessage = `You want to delete ${data.name}.`
    } else if (this.moduleId === MenuList.Feeder) {
      textMessage = `You want to delete ${data.feederName}.`
    } else if (this.moduleId === MenuList.Substation) {
      textMessage = `You want to delete ${data.substationName}.`
    } else if (this.moduleId === MenuList.Complaints) {
      textMessage = `You want to delete ${data.ticketId}.`
    } else {
      textMessage = `You want to delete ${data.firstName}.`
    }

    swal({
      title: 'Are you sure ?',
      text: textMessage,
      icon: 'warning',
      buttons: {
        cancel: {
          text: 'Cancel',
          visible: true,
          closeModal: true,
        },
        confirm: {
          text: 'Yes',
        },
      },
      dangerMode: true,
    }).then((confirmed) => {
      if (confirmed) {
        if (
          this.moduleId !== MenuList.Devices &&
          this.moduleId !== MenuList.Complaints &&
          this.moduleId !== MenuList.Feeder &&
          this.moduleId !== MenuList.Substation
        ) {
          this.toastr.success(NotificationMessage.RecordDeleteSuccessMsg)
        }

        this.deleteConfirmed.emit(data)
      }
    })
  }

  onComplaintClick(dataItem: any): void {
    this.triggerComplaintEdit.emit(dataItem)
  }

  onTrackLocation(dataItem: any): void {
    const googleMapsUrl = `https://www.google.com/maps?q=${dataItem.location}`
    window.open(googleMapsUrl, '_blank')
  }

  onDeviceClick(dataItem: any): void {
    if (this.commonService.checkPermission('/dashboard')) {
      this.store.dispatch(
        new SetDeviceId({ key: dataItem.deviceName, value: dataItem.deviceId }),
      )
      this.router.navigate(['dashboard'])
    }
  }

  onColumnSelector(): void {
    const dialogRef = this.dialogService.open({
      content: CommonColumnSelectorComponent,
      width: 450,
    })
    const columnSelector = dialogRef.content
      .instance as CommonColumnSelectorComponent

    columnSelector.columnsData = this.columns
    dialogRef.result.subscribe((res: any) => {
      if (res && res.confirmed) {
        this.triggerDataFromColumnSelector.emit()
      }
    })
  }

  filterChange(filter: CompositeFilterDescriptor): void {
    this.skip = 0
    this.filter = filter

    const isJSONFilter =
      this.moduleId === MenuList.GISInfo ||
      this.moduleId === MenuList.Devices ||
      this.moduleId === MenuList['SS SLD Dashboard'] ||
      this.selectedOfficeTypeList === OfficeTypeListEmun['Simcom Status'] ||
      this.selectedOfficeTypeList === OfficeTypeListEmun['System Status'] ||
      this.selectedOfficeTypeList === OfficeTypeListEmun['Loaded Settings'] ||
      this.selectedOfficeTypeList === OfficeTypeListEmun['Review Settings']

    this.filterChangeSQL.emit(
      isJSONFilter
        ? this.filter
        : this.commonService.toSQLExpression(this.filter),
    )
  }
}
