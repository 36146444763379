<div class="header d-flex gap-15 flex-wrap">
  <kendo-formfield *ngIf="showDateRange">
    <input
      type="text"
      ngxDaterangepickerMd
      class="date-range-input"
      [locale]="{ format: 'DD/MM/YYYY' }"
      [showClearButton]="true"
      [(ngModel)]="selectedDateRange"
      (change)="onDateRangeChange($event)"
      [linkedCalendars]="true"
      placeholder="Select Date Range"
    />
  </kendo-formfield>
  <kendo-formfield>
    <kendo-dropdownlist
      [data]="feederAreaList$ | async"
      [(ngModel)]="feederId"
      [textField]="'key'"
      [valueField]="'value'"
      [ngModelOptions]="{ standalone: true }"
      (selectionChange)="onFeederAreaChange($event)"
      class="w-200"
    ></kendo-dropdownlist>
  </kendo-formfield>
  <div *ngIf="!showAreaFilter">
    <kendo-formfield
      *ngIf="
        moduleId !== modules['Data Report'] &&
        moduleId !== modules['Meter Data']
      "
    >
      <kendo-dropdownlist
        [data]="deviceList$ | async"
        [(ngModel)]="deviceId"
        [textField]="'key'"
        [valueField]="'value'"
        [title]="'tooltip'"
        [ngModelOptions]="{ standalone: true }"
        (selectionChange)="onDeviceChange($event)"
        class="w-200"
      >
        <ng-template kendoDropDownListItemTemplate let-dataItem>
          <div kendoTooltip>
            <span [title]="dataItem.key">{{ dataItem.key }}</span>
          </div>
        </ng-template>
      </kendo-dropdownlist>
    </kendo-formfield>

    <kendo-formfield
      *ngIf="
        moduleId === modules['Meter Data'] ||
        moduleId === modules['Data Report']
      "
    >
      <kendo-dropdownlist
        [data]="deviceListAll$ | async"
        [(ngModel)]="deviceIdAll"
        [textField]="'key'"
        [valueField]="'value'"
        [title]="'tooltip'"
        [ngModelOptions]="{ standalone: true }"
        (selectionChange)="onDeviceChangeAll($event)"
        class="w-200"
      >
        <ng-template kendoDropDownListItemTemplate let-dataItem>
          <div kendoTooltip>
            <span [title]="dataItem.key">{{ dataItem.key }}</span>
          </div>
        </ng-template>
      </kendo-dropdownlist>
    </kendo-formfield>

    <kendo-formfield
      class="mt-0"
      *ngIf="moduleId === modules['Threshold Settings']"
    >
      <kendo-multiselect
        [data]="deviceList"
        [value]="selectedDevice"
        textField="key"
        valueField="value"
        [checkboxes]="true"
        (valueChange)="onMutliDeviceChange($event)"
        placeholder="Select Devices"
        [(ngModel)]="selectedDevice"
        [tagMapper]="tagMapper"
        [filterable]="true"
        (filterChange)="deviceFilterChange($event)"
      ></kendo-multiselect>
    </kendo-formfield>
  </div>
  <kendo-formfield *ngIf="!showAreaFilter && showDateFilter">
    <kendo-datepicker
      placeholder="Select date ..."
      [(ngModel)]="date"
      (valueChange)="onDateTimeChange($event)"
      calendarType="classic"
      class="w-200"
      format="dd/MM/yyyy"
    ></kendo-datepicker>
  </kendo-formfield>
</div>
