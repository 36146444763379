import { Component, OnInit, ViewChild } from '@angular/core'
import { DialogService } from '@progress/kendo-angular-dialog'
import {
  CommonColorInfoComponent,
  CommonGridComponent,
  CommonState,
  DataReportList,
} from '@app/index'
import { Select, Store } from '@ngxs/store'
import { NgxSpinnerService } from 'ngx-spinner'
import { GridDataResult } from '@progress/kendo-angular-grid'
import { interval, Observable, Subject, takeUntil } from 'rxjs'

@Component({
  selector: 'app-data-report-list',
  templateUrl: './data-report-list.component.html',
  styleUrls: ['./data-report-list.component.scss'],
})
export class DataReportListComponent implements OnInit {
  triggerGetUpdatedTimeWithData: Subject<any> = new Subject<any>()
  @ViewChild(CommonGridComponent) commonGridComponent:
    | CommonGridComponent
    | undefined

  gridView: GridDataResult | any
  // columns: any
  pageNumber = 1
  pageSize = 10
  skip = 0
  startDate = null
  endDate = null
  totalRecords = 0
  deviceId: any
  feederAreaId: any
  sort: any
  sortOrder = false
  previousSort: any = null
  @Select(CommonState.moduleId)
  moduleId$!: Observable<number>
  isDataLoaded = false
  filterQuery: string | any

  private destroy$ = new Subject<void>()

  constructor(
    private dialogService: DialogService,
    private store: Store,
    private spinner: NgxSpinnerService,
  ) {}

  ngOnInit(): void {
    this.setupInterval()
  }

  ngOnDestroy() {
    this.destroy$.next()
    this.destroy$.complete()
  }

  getDataFromColumnSelector(event: any) {
    this.getDataReport(
      this.deviceId,
      this.feederAreaId,
      this.startDate,
      this.endDate,
    )
  }

  setupInterval() {
    interval(60000)
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        this.getDataReport(
          this.deviceId,
          this.feederAreaId,
          this.startDate,
          this.endDate,
        )
      })
  }

  onColumnSelector(): void {
    this.commonGridComponent?.onColumnSelector()
  }

  onFilterChangeSQL(sqlQuery: any) {
    this.filterQuery = sqlQuery
    this.getDataReport(
      this.deviceId,
      this.feederAreaId,
      this.startDate,
      this.endDate,
    )
  }

  getDataReport(
    deviceId: any,
    feederAreaId: any,
    startDate: any,
    endDate: any,
  ): void {
    const param = {
      deviceId: deviceId,
      feederAreaId: feederAreaId,
      pageNumber: this.pageNumber,
      pageSize: this.pageSize,
      filter: 0,
      sortBy: this.sort ?? null,
      sortOrder: this.sortOrder,
      search: null,
      startDate: startDate,
      endDate: endDate,
      moduleId: this.store.selectSnapshot(CommonState.moduleId),
      filterQuery: this.filterQuery,
    }
    this.spinner.show()

    this.store.dispatch(new DataReportList(param)).subscribe(
      (res: any) => {
        if (res.dataReport.dataReportListData) {
          const { data, totalCount } = res.dataReport.dataReportListData

          this.gridView = {
            data: data.rows,
            total: totalCount,
            columns: data.columns,
          }
          this.spinner.hide()

          this.isDataLoaded = true
        }
      },
      (error) => {
        this.spinner.hide()
      },
    )
  }

  onPaginationChange({ skip, take }: any) {
    this.skip = skip
    this.pageSize = take
    this.pageNumber = this.skip / this.pageSize + 1

    this.getDataReport(
      this.deviceId,
      this.feederAreaId,
      this.startDate,
      this.endDate,
    )
  }

  onSortChange(sortData: any) {
    const currentSortField = sortData.sort[0].field
    if (this.previousSort === currentSortField) {
      this.sortOrder = !this.sortOrder
    } else {
      this.sortOrder = true
    }
    this.sort = currentSortField
    this.previousSort = currentSortField
    this.getDataReport(
      this.deviceId,
      this.feederAreaId,
      this.startDate,
      this.endDate,
    )
  }

  colorInfoClick(): void {
    const dialogRef = this.dialogService.open({
      content: CommonColorInfoComponent,
      width: 450,
    })

    dialogRef.content.instance as CommonColorInfoComponent
    dialogRef.result.subscribe()
  }

  getDataByHeaderData(event: any): void {
    this.deviceId = event.deviceId
    this.feederAreaId = event.feederAreaId
    this.startDate = event.startDate
    this.endDate = event.endDate
    this.getDataReport(
      event.deviceId,
      event.feederAreaId,
      event.startDate,
      event.endDate,
    )
    if (this.deviceId !== 'All') {
      this.triggerGetUpdatedTimeWithData.next(event)
    }
  }
}
