import { Component, Input, OnInit } from '@angular/core'
import { FormControl, FormGroup, Validators } from '@angular/forms'

@Component({
  selector: 'app-device-id-change',
  templateUrl: './device-id-change.component.html',
  styleUrl: './device-id-change.component.scss',
})
export class DeviceIdChangeComponent implements OnInit {
  deviceIdForm: FormGroup | any
  @Input()
  triggerDeviceIdData: any
  constructor() {}

  ngOnInit(): void {
    this.setForm()
  }

  setForm(): void {
    this.deviceIdForm = new FormGroup({
      newDeviceId: new FormControl('', [
        Validators.required,
        Validators.pattern('^[0-9]*$'),
      ]),
    })
  }

  getFormData() {
    return this.deviceIdForm.value
  }
}
