import { Component, OnInit } from '@angular/core'
import { FeederType, MenuList } from '@app/enums'
import { CommonService } from '@app/services'
import { GetDashboardDTRStatusDeviceList } from '@app/store'
import { Store } from '@ngxs/store'
import { DialogRef } from '@progress/kendo-angular-dialog'
import {
  GridDataResult,
  MultipleSortSettings,
  PagerPosition,
  PagerType,
} from '@progress/kendo-angular-grid'
import {
  CompositeFilterDescriptor,
  SortDescriptor,
} from '@progress/kendo-data-query'
import { NgxSpinnerService } from 'ngx-spinner'

@Component({
  selector: 'app-dashboard-device-status-popup',
  templateUrl: './dashboard-device-status-popup.component.html',
  styleUrl: './dashboard-device-status-popup.component.scss',
})
export class DashboardDeviceStatusPopupComponent implements OnInit {
  statusName: string | any
  feederAreaId: string | any
  dashboardDtrStatus: any
  columns: any[] = []
  moduleId: any = MenuList['Dashboard Device Status']
  isDTR?: boolean
  gridView: GridDataResult | any

  gridHeight: number = 0
  pagerposition: PagerPosition = 'bottom'
  skip = 0
  type: PagerType = 'numeric'
  sort: SortDescriptor[] = []
  previousSort: any = null
  pageNumber = 1
  pageSize = 10

  sortOrder = true
  sortBy: any
  isPaginationAllowed: boolean = true
  buttonCount = 5
  info = true
  pageSizes = [5, 10, 20, 50, 100]
  previousNext = true
  multiple = false
  allowUnsort = true
  isSortingAllowed: boolean = true

  filterQuery: string | any
  sortSettings: MultipleSortSettings = {
    mode: 'multiple',
    initialDirection: 'desc',
    allowUnsort: true,
    showIndexes: true,
  }

  public filter: CompositeFilterDescriptor = {
    logic: 'and',
    filters: [],
  }

  constructor(
    public dialogRef: DialogRef,
    public commonService: CommonService,
    private store: Store,
    private spinner: NgxSpinnerService,
  ) {}

  ngOnInit(): void {
    this.columns = this.commonService.getColumnNameByModule(
      this.moduleId,
      this.dashboardDtrStatus,
    )
    if (this.columns.length) {
      this.sort = this.columns.map((column) => ({ field: column.field }))
    }

    this.getDashboardDTRStatusDeviceList()
  }

  getDashboardDTRStatusDeviceList(): void {
    const param = {
      pageNumber: this.pageNumber,
      pageSize: this.pageSize,
      sortBy: this.sortBy,
      sortOrder: this.sortOrder,
      filterQueryJson: this.filterQuery,
      moduleId: this.moduleId,
      feederAreaId: this.feederAreaId.value,
    }

    const feederType = this.isDTR ? FeederType.DTR : FeederType.PTR
    this.spinner.show()
    this.store
      .dispatch(
        new GetDashboardDTRStatusDeviceList(
          this.dashboardDtrStatus,
          param,
          feederType,
        ),
      )
      .subscribe(
        (res) => {
          this.spinner.hide()
          const response = res.dashboarddtr.dashboardDtrStateDeviceList
          this.gridView = {
            data: response.data.rows[0].dashboardDetails,
            total: response.totalCount,
          }
        },
        (error: any) => {
          this.spinner.hide()
        },
      )
  }

  onTrackLocation(dataItem: any): void {
    const googleMapsUrl = `https://www.google.com/maps?q=${dataItem.location}`
    window.open(googleMapsUrl, '_blank')
  }

  onFilterChangeSQL(sqlQuery: any) {
    this.skip = 0
    this.pageNumber = 1
    this.filterQuery = sqlQuery
    this.filter = sqlQuery
    this.getDashboardDTRStatusDeviceList()
  }

  onPaginationChange({ skip, take }: any) {
    this.skip = skip
    this.pageSize = take
    this.pageNumber = this.skip / this.pageSize + 1
    this.getDashboardDTRStatusDeviceList()
  }

  onSortChange(sortData: any): void {
    this.sort = sortData
    const currentSortField = sortData[0].field
    this.sortBy = currentSortField
    if (this.previousSort === currentSortField) {
      this.sortOrder = !this.sortOrder
    } else {
      this.sortOrder = true
    }
    this.previousSort = currentSortField
    this.getDashboardDTRStatusDeviceList()
  }

  onCloseDialog(): void {
    this.commonService.onDialogClose(this.dialogRef, false)
  }
}
