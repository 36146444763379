export enum NotificationMessage {
  deviceIdSuccessMsg = 'Device Id Updated Successfully!',
  energyDataSuccessMsg = 'Energy Data Updated Successfully!',
  UnbalancedLoadDataSuccessMsg = 'Unbalanced Load Data Updated Successfully!',
  wifiDataSuccessMsg = 'Unbalanced Load Data Updated Successfully!',
  angleDataSuccessMsg = 'AngelData Updated Successfully!',
  deviceSerialDataSuccessMsg = 'Device Serial Data Updated Successfully!',
  AccountCreatedSuccessMsg = 'Account Created Successfully',
  AccountUpdatedSuccessMsg = 'Account Updated Successfully',
  RecordDeleteSuccessMsg = 'Record Deleted Successfully',
  DeviceDeletedSuccessMsg = 'Successfully Removed From Account..!',
  DeviceCreatedSuccessMsg = 'Device Created Successfully',
  ComplaintCreatedSuccessMsg = 'Complaint Created Successfully',
  ComplaintUpdatedSuccessMsg = 'Complaint Updated Successfully',
  CommentCreatedSuccessMsg = 'Comment Created Successfully',
  DeviceUpdatedSuccessMsg = 'Device Updated Successfully',
  DTRDataSuccessMsg = 'DTRs Data Submitted Successfully',
  RoleCreatedSuccessMsg = 'Role Created Successfully',
  RoleUpdatedSuccessMsg = 'Role Updated Successfully',
  AlertSettingSuccessMsg = 'Alert Setting Saved Successfully',
  MinVoltageSuccessMsg = 'Min Voltage (V) Updated Successfully',
  MaxVoltageSuccessMsg = 'Max Voltage (V) Updated Successfully',
  MaxCurrentSuccessMsg = 'Max Current (A) Updated Successfully',
  kVASuccessMsg = 'kVA Updated Successfully',
  CTSettingSuccessMsg = 'CT Settings (A) Updated Successfully',
  TemperatureSuccessMsg = 'Temperature Updated Successfully',
  PfSettingSuccessMsg = 'PF Settings Updated Successfully',
  UnityPfSuccessMsg = 'Unity PF Updated Successfully',
  PagePErmissionSaveSuccessMsg = 'Page Permission saved Successfully',
  ColumnPermissionSaveSuccessMsg = 'Column Permission saved Successfully',
  readDataSuccessMsg = 'IoT Settings read Successfully.',
  readDataErrorMsg = 'Failed to read IoT Settings.',
  loadedSettingMsg = 'Loaded Setting Saved Successfully',
  signupMessage = 'Please Contact ISTLABS For Regitration',
  forgotPasswordSuccessMsg = 'Forgot password request processed Successfully',
  forgotPasswordErrorMsg = 'Failed to process forgot password request',
  otpSendSuccessMsg = 'OTP send to your registered mobile number and e-mail.',
  otpNotValidMsg = 'Please enter valid OTP!',
  imageInputNotValidMsg = 'Please select image only!',
  feederSuccessMsg = 'Device assigned to Feeder Successfully',
  subStationSuccessMsg = 'Feeder assigned to SubStation Successfully',
  errorMsg = 'Sorry! There is some error',
  noDeviceErrorMsg = 'No device associate to this Feeder area',
  columnUpdateSuccessMsg = 'Columns Updated Successfully',
  deviceSelectErrorMsg = 'Please Select the Device(s) For Feeder..!',
  feederSelectErrorMsg = 'Please Select the Feeder(s) For SubStation..!',
  fileUploadSuccessMsg = 'File uploaded Successfully',
  sameRoleNameErrorMsg = 'Role Name already exist',
  sameSubstationErrorMsg = 'Substation Name already exist',
  sameAccountNameErrorMsg = 'Account Name already exist',
  createFeederSuccessMsg = 'Feeder Created Successfully',
  updateFeederSuccessMsg = 'Feeder Updated Successfully',
  deleteFeederSuccessMsg = 'Feeder Deleted Successfully',
  deleteSubstationSuccessMsg = 'Substation Deleted Successfully',
  createSubstationSuccessMsg = 'Substation Created Successfully',
  updateSubstationSuccessMsg = 'Substation Updated Successfully',
  activationCodeNotFound = 'Activation code for the device not found',
  deleteTicketSuccessMsg = 'Ticket Deleted Successfully',
  deviceAssignToAccountSuccessMsg = 'Device assigned to Account Successfully',
  noDeviceSelected = 'Please Select the Device(s)..!',
  DeviceInstallDateUpdatedSuccessMsg = 'Device Install Date Add Successfully',
  prtDeviceSelectionErrorMsg = 'Since your Feeder Type is PTR, please select single device..!',
  selectAnyDevice = 'Please select any device',
  startDateValidation = 'Please select start date',
  endDateValidation = 'Please select end date',
  startEndDateCompareValidation = 'End date should be greater than start date',
  downloadProgressMsg = 'Your download is in progress. We will notify you once it is complete.',
  downloadSuccessMsg = 'Download complete! You can now access your file.',
  dumpToExcelProgressMsg = 'Your Dump to Excel is in progress. We will notify you once it is complete.',
  dumpToSqlProgressMsg = 'Your Dump to Sql is in progress. We will notify you once it is complete.',
  dumpToExcelSuccessMsg = 'Dump to Excel complete! You can now access your file.',
  dumpToSqlSuccessMsg = 'Dump to Sql complete! You can now access your file.',
  databaseDeleteProgressMsg = 'The database deletion is in progress. We will notify you once it is complete.',
  databaseDeleteSuccessMsg = 'The database has been successfully deleted.',
}

export enum ColumnMessagesforGrid {
  NoColumnsAssignedMessage = 'No Columns Assigned to Role!',
  NoColumnsAssignedDetailMessage = 'It appears that there are no columns assigned to the role you are trying to access. Please contact your system administrator for assistance.',
  NoColumnsSelectedMessage = 'No columns have been selected!',
  NoColumnsSelectedDetailMessage = 'It seems you don’t have selected any columns from customized columns to show here.',
}
