import { addMonths, cloneDate, createDate, isEqual, getDate, lastDayOfMonth } from '@progress/kendo-date-math';
import { Mask } from './mask';
import { dateSymbolMap, padZero, unpadZero } from '../dateinput/utils';
import { extend, isPresent, cropTwoDigitYear, setYears, parseToInt, clamp, areDatePartsEqualTo, isNumber, isValidDate } from './utils';
import { Constants } from './constants';
const MONTH_INDEX_FEBRUARY = 1;
const DEFAULT_LEAP_YEAR = 2000;
const PREVIOUS_CENTURY_BASE = 1900;
const CURRENT_CENTURY_BASE = 2000;
const SHORT_PATTERN_LENGTH_REGEXP = /d|M|H|h|m|s/;
const MONTH_PART_WITH_WORDS_THRESHOLD = 2;
const MONTH_SYMBOL = "M";
// JS months start from 0 (January) instead of 1 (January)
const JS_MONTH_OFFSET = 1;
export class DateObject {
    constructor({ intlService, formatPlaceholder, format, cycleTime = false, twoDigitYearMax = Constants.twoDigitYearMax, value = null, autoCorrectParts = true }) {
        this.year = true;
        this.month = true;
        this.date = true;
        this.hours = true;
        this.minutes = true;
        this.seconds = true;
        this.milliseconds = true;
        this.dayperiod = true;
        this.leadingZero = null;
        this.typedMonthPart = '';
        this.knownParts = 'adHhmMsEyS';
        this.symbols = {
            'E': 'E',
            'H': 'H',
            'M': 'M',
            'a': 'a',
            'd': 'd',
            'h': 'h',
            'm': 'm',
            's': 's',
            'y': 'y',
            'S': 'S'
        };
        this._value = this.getDefaultDate();
        this.cycleTime = false;
        this._partiallyInvalidDate = {
            startDate: null,
            invalidDateParts: {
                'E': { value: null, date: null, startDateOffset: 0 },
                'H': { value: null, date: null, startDateOffset: 0 },
                'M': { value: null, date: null, startDateOffset: 0 },
                'a': { value: null, date: null, startDateOffset: 0 },
                'd': { value: null, date: null, startDateOffset: 0 },
                'h': { value: null, date: null, startDateOffset: 0 },
                'm': { value: null, date: null, startDateOffset: 0 },
                's': { value: null, date: null, startDateOffset: 0 },
                'y': { value: null, date: null, startDateOffset: 0 },
                'S': { value: null, date: null, startDateOffset: 0 }
            }
        };
        this.setOptions({
            intlService,
            formatPlaceholder,
            format,
            cycleTime,
            twoDigitYearMax,
            value,
            autoCorrectParts
        });
        if (!value) {
            this._value = this.getDefaultDate();
            const sampleFormat = this.dateFormatString(this.value, this.format).symbols;
            for (let i = 0; i < sampleFormat.length; i++) {
                this.setExisting(sampleFormat[i], false);
            }
        }
        else {
            this._value = cloneDate(value);
        }
    }
    set value(value) {
        if (value && !(value instanceof Date)) {
            // throw new Error("The 'value' should be a valid JavaScript Date instance.");
            return;
        }
        this._value = value;
        this.resetInvalidDate();
    }
    get value() {
        return this._value;
    }
    get localeId() {
        let localeId = Constants.defaultLocaleId;
        const cldrKeys = Object.keys(this.intl.cldr);
        for (let i = 0; i < cldrKeys.length; i++) {
            const key = cldrKeys[i];
            const value = this.intl.cldr[key];
            if (value.name && value.calendar && value.numbers &&
                value.name !== Constants.defaultLocaleId) {
                localeId = value.name;
                break;
            }
        }
        return localeId;
    }
    /**
     * @hidden
     */
    setOptions(options) {
        this.intl = options.intlService;
        this.formatPlaceholder = options.formatPlaceholder || 'wide';
        this.format = options.format;
        this.cycleTime = options.cycleTime;
        this.monthNames = this.allFormattedMonths(this.localeId);
        this.dayPeriods = this.allDayPeriods(this.localeId);
        this.twoDigitYearMax = options.twoDigitYearMax;
        this.autoCorrectParts = options.autoCorrectParts;
    }
    setValue(value) {
        if (!value) {
            this._value = this.getDefaultDate();
            this.modifyExisting(false);
        }
        else if (!isEqual(value, this._value)) {
            this._value = cloneDate(value);
            this.modifyExisting(true);
        }
        this.resetInvalidDate();
    }
    /**
     * @hidden
     */
    hasValue() {
        const pred = (a, p) => a || p.type !== 'literal' && p.type !== 'dayperiod' && this.getExisting(p.pattern[0]);
        return this.intl.splitDateFormat(this.format, this.localeId).reduce(pred, false);
    }
    /**
     * @hidden
     */
    getValue() {
        for (let i = 0; i < this.knownParts.length; i++) {
            if (!this.getExisting(this.knownParts[i])) {
                return null;
            }
        }
        return cloneDate(this.value);
    }
    /**
     * @hidden
     */
    createDefaultDate() {
        // use the leap year 2000 that has 29th February
        // and a month that has 31 days
        // so that the default date can accommodate maximum date values
        // it is better to use a fixed date instead of new Date()
        // as otherwise the
        return createDate(DEFAULT_LEAP_YEAR, 0, 31);
    }
    /**
     * @hidden
     */
    getDefaultDate() {
        return getDate(this.createDefaultDate());
    }
    /**
     * @hidden
     */
    getFormattedDate(format) {
        return this.intl.formatDate(this.getValue(), format, this.localeId);
    }
    /**
     * @hidden
     */
    getTextAndFormat(customFormat = "") {
        const format = customFormat || this.format;
        let text = this.intl.formatDate(this.value, format, this.localeId);
        const mask = this.dateFormatString(this.value, format);
        if (!this.autoCorrectParts && this._partiallyInvalidDate.startDate) {
            let partiallyInvalidText = "";
            const formattedDate = this.intl.formatDate(this.value, format, this.localeId);
            const formattedDates = this.getFormattedInvalidDates(format);
            for (let i = 0; i < formattedDate.length; i++) {
                const symbol = mask.symbols[i];
                if (mask.partMap[i].type === "literal") {
                    partiallyInvalidText += text[i];
                }
                else if (this.getInvalidDatePartValue(symbol)) {
                    const partsForSegment = this.getPartsForSegment(mask, i);
                    if (symbol === "M") {
                        const datePartText = (parseToInt(this.getInvalidDatePartValue(symbol)) + JS_MONTH_OFFSET).toString();
                        if (partsForSegment.length > MONTH_PART_WITH_WORDS_THRESHOLD) {
                            partiallyInvalidText += formattedDates[symbol][i];
                        }
                        else {
                            if (this.getInvalidDatePartValue(symbol)) {
                                const formattedDatePart = padZero(partsForSegment.length - datePartText.length) + datePartText;
                                partiallyInvalidText += formattedDatePart;
                                // add -1 as the first character in the segment is at index i
                                i += partsForSegment.length - 1;
                            }
                            else {
                                partiallyInvalidText += formattedDates[symbol][i];
                            }
                        }
                    }
                    else {
                        if (this.getInvalidDatePartValue(symbol)) {
                            const datePartText = this.getInvalidDatePartValue(symbol).toString();
                            const formattedDatePart = padZero(partsForSegment.length - datePartText.length) + datePartText;
                            partiallyInvalidText += formattedDatePart;
                            // add -1 as the first character in the segment is at index i
                            i += partsForSegment.length - 1;
                        }
                        else {
                            partiallyInvalidText += formattedDates[symbol][i];
                        }
                    }
                }
                else {
                    partiallyInvalidText += text[i];
                }
            }
            text = partiallyInvalidText;
        }
        const result = this.merge(text, mask);
        return result;
    }
    /**
     * @hidden
     */
    getFormattedInvalidDates(customFormat = "") {
        const format = customFormat || this.format;
        let formattedDatesForSymbol = {
            'E': '',
            'H': '',
            'M': '',
            'a': '',
            'd': '',
            'h': '',
            'm': '',
            's': '',
            'y': '',
            'S': ''
        };
        Object.keys(this._partiallyInvalidDate.invalidDateParts).forEach(key => {
            const date = this.getInvalidDatePart(key).date;
            if (date) {
                const formattedInvalidDate = this.intl.formatDate(date, format, this.localeId);
                formattedDatesForSymbol[key] = formattedInvalidDate;
            }
        });
        return formattedDatesForSymbol;
    }
    modifyExisting(value) {
        const sampleFormat = this.dateFormatString(this.value, this.format).symbols;
        for (let i = 0; i < sampleFormat.length; i++) {
            this.setExisting(sampleFormat[i], value);
        }
    }
    /**
     * @hidden
     */
    getExisting(symbol) {
        switch (symbol) {
            case 'y': return this.year;
            case 'M':
            case 'L': return this.month;
            case 'd': return this.date;
            case 'E': return this.date && this.month && this.year;
            case 'h':
            case 'H': return this.hours;
            case 't':
            case 'a': return this.dayperiod;
            case 'm': return this.minutes;
            case 's': return this.seconds;
            case "S": return this.milliseconds;
            default:
                return true;
        }
    }
    setExisting(symbol, value) {
        switch (symbol) {
            case 'y':
                // allow 2/29 dates
                this.year = value;
                if (value === false) {
                    this._value.setFullYear(DEFAULT_LEAP_YEAR);
                }
                break;
            case 'M':
                // make sure you can type 31 in the day part
                this.month = value;
                if (value === false) {
                    if (this.autoCorrectParts) {
                        this._value.setMonth(0);
                    }
                }
                break;
            case 'd':
                this.date = value;
                break;
            case 'h':
            case 'H':
                this.hours = value;
                break;
            case 't':
            case 'a':
                this.dayperiod = value;
                break;
            case 'm':
                this.minutes = value;
                break;
            case 's':
                this.seconds = value;
                break;
            case "S":
                this.milliseconds = value;
                break;
            default:
                break;
        }
        if (this.getValue()) {
            this.resetInvalidDate();
        }
    }
    modifyPart(symbol, offset) {
        if (!isPresent(symbol) || !isPresent(offset) || offset === 0) {
            return;
        }
        let newValue = cloneDate(this.value);
        let timeModified = false;
        let invalidDateFound;
        const isMonth = symbol === "M";
        const isDay = symbol === "d" || symbol === "E";
        const symbolExists = this.getExisting(symbol);
        if (!this.autoCorrectParts && (isDay || isMonth)) {
            const invalidDateParts = this._partiallyInvalidDate.invalidDateParts || {};
            const invalidDatePartValue = this.getInvalidDatePartValue(symbol);
            let year = invalidDateParts.y.value || newValue.getFullYear();
            let month = invalidDateParts.M.value || newValue.getMonth();
            let day = invalidDateParts.d.value || invalidDateParts.E.value || newValue.getDate();
            let hour = invalidDateParts.h.value || invalidDateParts.H.value || newValue.getHours();
            let minutes = invalidDateParts.m.value || newValue.getMinutes();
            let seconds = invalidDateParts.s.value || newValue.getSeconds();
            let milliseconds = invalidDateParts.S.value || newValue.getMilliseconds();
            switch (symbol) {
                case 'y':
                    year += offset;
                    break;
                case 'M':
                    month += offset;
                    break;
                case 'd':
                case 'E':
                    day += offset;
                    break;
                // case 'h':
                // case 'H': hour += offset; break;
                // case 'm': minutes += offset; break;
                // case 's': seconds += offset; break;
                // case 'S': milliseconds += offset; break;
                default: break;
            }
            if (symbol === "M") {
                if ((month < 0 || month > 11)) {
                    if (symbolExists) {
                        this.setExisting(symbol, false);
                        this.resetInvalidDateSymbol(symbol);
                        return;
                    }
                }
                if (!symbolExists) {
                    if (month < 0) {
                        month = clamp(11 + ((month % 11) + 1), 0, 11);
                    }
                    else {
                        const monthValue = isPresent(invalidDatePartValue) ?
                            month :
                            ((offset - JS_MONTH_OFFSET) % 12);
                        month = clamp(monthValue, 0, 11);
                    }
                    month = clamp(month, 0, 11);
                }
                month = clamp(month, 0, 11);
            }
            else if (symbol === "d") {
                if (symbolExists) {
                    if (day <= 0 || day > 31) {
                        this.setExisting(symbol, false);
                        this.resetInvalidDateSymbol(symbol);
                        return;
                    }
                }
                else if (!symbolExists) {
                    if (isPresent(invalidDatePartValue)) {
                        if (day <= 0 || day > 31) {
                            this.setExisting(symbol, false);
                            this.resetInvalidDateSymbol(symbol);
                            return;
                        }
                    }
                    if (offset < 0) {
                        const dayValue = isPresent(invalidDatePartValue) ? day : 1 + (31 - Math.abs(offset % 31));
                        day = clamp(dayValue, 1, 31);
                    }
                    else {
                        const dayValue = isPresent(invalidDatePartValue) ? day : offset % 31;
                        day = clamp(dayValue, 1, 31);
                    }
                    day = clamp(day, 1, 31);
                }
            }
            const dateCandidate = createDate(year, month, day, hour, minutes, seconds, milliseconds);
            const newValueCandidate = isMonth || isDay ?
                this.modifyDateSymbolWithValue(newValue, symbol, isMonth ? month : day) :
                null;
            const dateCandidateExists = areDatePartsEqualTo(dateCandidate, year, month, day, hour, minutes, seconds, milliseconds);
            if (this.getValue() && areDatePartsEqualTo(dateCandidate, year, month, day, hour, minutes, seconds, milliseconds)) {
                newValue = cloneDate(dateCandidate);
                this.markDatePartsAsExisting();
            }
            else if (isMonth && newValueCandidate) {
                if (newValueCandidate.getMonth() === month) {
                    if (this.getExisting("d")) {
                        if (dateCandidateExists) {
                            newValue = cloneDate(dateCandidate);
                            this.resetInvalidDateSymbol(symbol);
                        }
                        else {
                            invalidDateFound = true;
                            this.setInvalidDatePart(symbol, {
                                value: month,
                                date: cloneDate(newValueCandidate),
                                startDateOffset: offset,
                                startDate: cloneDate(this.value)
                            });
                            this.setExisting(symbol, false);
                        }
                    }
                    else if (dateCandidateExists) {
                        this.resetInvalidDateSymbol(symbol);
                        newValue = cloneDate(dateCandidate);
                        if (this.getExisting("M") && this.getExisting("y")) {
                            // changing from 28/Feb to 29/Feb to 29/March
                            this.setExisting("d", true);
                            this.resetInvalidDateSymbol("d");
                        }
                    }
                    else {
                        this.resetInvalidDateSymbol(symbol);
                        newValue = cloneDate(newValueCandidate);
                    }
                }
                else {
                    invalidDateFound = true;
                    this.setInvalidDatePart(symbol, {
                        value: month,
                        date: cloneDate(newValueCandidate),
                        startDateOffset: offset,
                        startDate: cloneDate(this.value)
                    });
                    this.setExisting(symbol, false);
                }
            }
            else if (isDay && newValueCandidate) {
                if (newValueCandidate.getDate() === day) {
                    if (this.getExisting("M")) {
                        if (dateCandidateExists) {
                            newValue = cloneDate(dateCandidate);
                            this.resetInvalidDateSymbol(symbol);
                        }
                        else {
                            invalidDateFound = true;
                            this.setInvalidDatePart(symbol, {
                                value: day,
                                date: cloneDate(newValueCandidate),
                                startDateOffset: offset,
                                startDate: cloneDate(this.value)
                            });
                            this.setExisting(symbol, false);
                        }
                    }
                    else if (dateCandidateExists) {
                        newValue = cloneDate(dateCandidate);
                        this.resetInvalidDateSymbol(symbol);
                        if (this.getExisting("d") && this.getExisting("y")) {
                            // changing from 31/Jan to 31/Feb to 28/Feb
                            this.setExisting("M", true);
                            this.resetInvalidDateSymbol("M");
                        }
                    }
                    else {
                        this.resetInvalidDateSymbol(symbol);
                        newValue = cloneDate(newValueCandidate);
                    }
                }
                else {
                    invalidDateFound = true;
                    this.setInvalidDatePart(symbol, {
                        value: day,
                        date: cloneDate(this.value),
                        startDateOffset: offset,
                        startDate: cloneDate(this.value)
                    });
                    this.setExisting(symbol, false);
                }
            }
        }
        else {
            switch (symbol) {
                case 'y':
                    newValue.setFullYear(newValue.getFullYear() + offset);
                    break;
                case 'M':
                    newValue = addMonths(this.value, offset);
                    break;
                case 'd':
                case 'E':
                    newValue.setDate(newValue.getDate() + offset);
                    break;
                case 'h':
                case 'H':
                    newValue.setHours(newValue.getHours() + offset);
                    timeModified = true;
                    break;
                case 'm':
                    newValue.setMinutes(newValue.getMinutes() + offset);
                    timeModified = true;
                    break;
                case 's':
                    newValue.setSeconds(newValue.getSeconds() + offset);
                    timeModified = true;
                    break;
                case "S":
                    newValue.setMilliseconds(newValue.getMilliseconds() + offset);
                    break;
                case 'a':
                    newValue.setHours(newValue.getHours() + (12 * offset));
                    timeModified = true;
                    break;
                default: break;
            }
        }
        if (this.shouldNormalizeCentury()) {
            newValue = this.normalizeCentury(newValue);
        }
        if (timeModified && !this.cycleTime && newValue.getDate() !== this._value.getDate()) {
            // todo: blazor has this fix, but this fails a unit test
            // newValue.setDate(this._value.getDate());
            // newValue.setMonth(this._value.getMonth());
            // newValue.setFullYear(this._value.getFullYear());
        }
        if (!invalidDateFound) {
            this.setExisting(symbol, true);
            this._value = newValue;
            if (this.getValue()) {
                this.resetInvalidDate();
            }
        }
    }
    /**
     * @hidden
     */
    parsePart({ symbol, currentChar, resetSegmentValue, cycleSegmentValue, rawTextValue: rawInputValue, isDeleting, originalFormat }) {
        const isInCaretMode = !cycleSegmentValue;
        const dateParts = this.dateFormatString(this.value, this.format);
        const datePartsLiterals = dateParts.partMap
            .filter(x => x.type === "literal")
            .map((x, index) => {
            return {
                datePartIndex: index,
                type: x.type,
                pattern: x.pattern,
                literal: ""
            };
        });
        const flatDateParts = dateParts.partMap
            .map((x) => {
            return {
                type: x.type,
                pattern: x.pattern,
                text: ""
            };
        });
        for (let i = 0; i < datePartsLiterals.length; i++) {
            const datePart = datePartsLiterals[i];
            for (let j = 0; j < datePart.pattern.length; j++) {
                if (datePartsLiterals[i + j]) {
                    datePartsLiterals[i + j].literal = datePart.pattern[j];
                }
            }
            i += datePart.pattern.length - 1;
        }
        for (let i = 0; i < flatDateParts.length; i++) {
            const datePart = flatDateParts[i];
            for (let j = 0; j < datePart.pattern.length; j++) {
                if (flatDateParts[i + j]) {
                    flatDateParts[i + j].text = datePart.pattern[j];
                }
            }
            i += datePart.pattern.length - 1;
        }
        let shouldResetPart = isInCaretMode && symbol === "M" && dateParts.partMap
            .filter(x => x.type === "month")
            .some(x => x.pattern.length > MONTH_PART_WITH_WORDS_THRESHOLD);
        let parseResult = {
            value: null,
            switchToNext: false,
            resetPart: shouldResetPart,
            hasInvalidDatePart: false
        };
        if (!currentChar) {
            if (isInCaretMode) {
                for (let i = 0; i < datePartsLiterals.length; i++) {
                    const literal = datePartsLiterals[i].literal;
                    const rawValueStartsWithLiteral = rawInputValue.startsWith(literal);
                    const rawValueEndsWithLiteral = rawInputValue.endsWith(literal);
                    const rawValueHasConsecutiveLiterals = rawInputValue.indexOf(literal + literal) >= 0;
                    if (rawValueStartsWithLiteral || rawValueEndsWithLiteral || rawValueHasConsecutiveLiterals) {
                        this.resetLeadingZero();
                        this.setExisting(symbol, false);
                        this.resetInvalidDateSymbol(symbol);
                        return extend(parseResult, { value: null, switchToNext: false });
                    }
                }
            }
            else {
                this.resetLeadingZero();
                this.setExisting(symbol, false);
                this.resetInvalidDateSymbol(symbol);
                return extend(parseResult, { value: null, switchToNext: false });
            }
        }
        const baseDate = this.intl.formatDate(this.value, this.format, this.localeId);
        const baseFormat = dateParts.symbols;
        let replaced = false;
        let prefix = '';
        let current = '';
        let datePartText = '';
        let basePrefix = '';
        let baseSuffix = '';
        let suffix = '';
        let convertedBaseFormat = "";
        for (let i = 0; i < flatDateParts.length; i++) {
            convertedBaseFormat += flatDateParts[i].text;
        }
        const hasFixedFormat = (this.format === baseFormat) ||
            (this.format === convertedBaseFormat) ||
            (this.format === originalFormat) ||
            (this.format.length === originalFormat.length);
        const datePartStartIndex = (hasFixedFormat ? convertedBaseFormat : originalFormat).indexOf(symbol);
        const datePartEndIndex = (hasFixedFormat ? convertedBaseFormat : originalFormat).lastIndexOf(symbol);
        const segmentLength = datePartEndIndex - datePartStartIndex + 1;
        let formatToTextLengthDiff = originalFormat.length - rawInputValue.length;
        if (isInCaretMode || (!isInCaretMode && !this.autoCorrectParts)) {
            for (let i = 0; i < baseDate.length; i++) {
                if (baseFormat[i] === symbol) {
                    const existing = this.getExisting(symbol);
                    current += existing ? baseDate[i] : '0';
                    if (formatToTextLengthDiff > 0) {
                        if (datePartText.length + formatToTextLengthDiff < segmentLength) {
                            datePartText += rawInputValue[i] || "";
                        }
                    }
                    else {
                        datePartText += rawInputValue[i] || "";
                    }
                    replaced = true;
                }
                else if (!replaced) {
                    prefix += baseDate[i];
                    basePrefix += baseDate[i];
                }
                else {
                    suffix += baseDate[i];
                    baseSuffix += baseDate[i];
                }
            }
            if (hasFixedFormat) {
                if (convertedBaseFormat.length < rawInputValue.length) {
                    datePartText += currentChar;
                }
                else if (!isDeleting && originalFormat.length > rawInputValue.length) {
                    // let the parsing to determine if the incomplete value is valid
                }
                if (datePartText.length > segmentLength) {
                    return extend(parseResult, { value: null, switchToNext: false });
                }
            }
            if (!hasFixedFormat || (hasFixedFormat && !this.autoCorrectParts)) {
                current = "";
                datePartText = "";
                prefix = "";
                suffix = "";
                replaced = false;
                for (let i = 0; i < originalFormat.length; i++) {
                    if (originalFormat[i] === symbol) {
                        const existing = this.getExisting(symbol);
                        current += existing ? baseDate[i] || "" : '0';
                        if (formatToTextLengthDiff > 0) {
                            if (datePartText.length + formatToTextLengthDiff < segmentLength) {
                                datePartText += rawInputValue[i] || "";
                            }
                        }
                        else {
                            datePartText += rawInputValue[i] || "";
                        }
                        replaced = true;
                    }
                    else if (!replaced) {
                        prefix += rawInputValue[i] || "";
                    }
                    else {
                        suffix += rawInputValue[i - formatToTextLengthDiff] || "";
                    }
                }
                if (originalFormat.length < rawInputValue.length) {
                    datePartText += currentChar;
                }
            }
        }
        if (!isInCaretMode) {
            if (this.autoCorrectParts) {
                current = "";
                datePartText = "";
                prefix = "";
                suffix = "";
                replaced = false;
                for (let i = 0; i < baseDate.length; i++) {
                    if (baseFormat[i] === symbol) {
                        const existing = this.getExisting(symbol);
                        current += existing ? baseDate[i] : '0';
                        replaced = true;
                    }
                    else if (!replaced) {
                        prefix += baseDate[i];
                    }
                    else {
                        suffix += baseDate[i];
                    }
                }
            }
            else {
                current = resetSegmentValue ? datePartText : current;
            }
        }
        let parsedDate = null;
        let month = this.matchMonth(currentChar);
        const dayPeriod = this.matchDayPeriod(currentChar, symbol);
        const isZeroCurrentChar = currentChar === '0';
        const leadingZero = this.leadingZero || {};
        if (isZeroCurrentChar) {
            if (datePartText === "0") {
                datePartText = current;
            }
            let valueNumber = parseToInt(resetSegmentValue ?
                currentChar :
                (isInCaretMode ? datePartText : current) + currentChar);
            if (valueNumber === 0 && !this.isAbbrMonth(dateParts.partMap, symbol)) {
                this.incrementLeadingZero(symbol);
            }
        }
        else {
            this.resetLeadingZero();
        }
        const partPattern = this.partPattern(dateParts.partMap, symbol);
        const patternValue = partPattern ? partPattern.pattern : null;
        const patternLength = this.patternLength(patternValue) || patternValue.length;
        if (isInCaretMode) {
            if (isDeleting && !datePartText) {
                this.setExisting(symbol, false);
                return extend(parseResult, { value: null, switchToNext: false });
            }
        }
        const currentMaxLength = current.length - 3;
        let tryParse = true;
        let middle = isInCaretMode ? datePartText : current;
        for (let i = Math.max(0, currentMaxLength); i <= current.length; i++) {
            if (!tryParse) {
                break;
            }
            middle = resetSegmentValue ?
                currentChar :
                isInCaretMode ?
                    datePartText :
                    (current.substring(i) + currentChar);
            if (isInCaretMode || !this.autoCorrectParts) {
                tryParse = false;
                middle = unpadZero(middle);
                // middle = padZero(segmentLength - middle.length) + middle;
                middle = padZero(patternLength - middle.length) + middle;
            }
            let middleNumber = parseInt(middle, 10);
            const candidateDateString = prefix + middle + suffix;
            parsedDate = this.intl.parseDate(candidateDateString, this.format, this.localeId);
            let autoCorrectedPrefixAndSuffix = false;
            if (isInCaretMode && !isValidDate(parsedDate)) {
                // if part of the date is not available, e.g. "d"
                // but an expanded format like "F" is used
                // the element value can be "EEEE, February 1, 2022 3:04:05 AM"
                // which is not parsable by intl
                // use the base prefix and suffix, e.g. convert the candidate date string
                // to "Thursday, February 1, 2022 3:04:05 AM"
                // as "EEEE, February..." is not parsable
                if (this.autoCorrectParts) {
                    parsedDate = this.intl.parseDate(basePrefix + middle + baseSuffix, this.format, this.localeId);
                    autoCorrectedPrefixAndSuffix = true;
                }
            }
            const isCurrentCharParsable = !isNaN(parseInt(currentChar, 10)) || (isInCaretMode && isDeleting && currentChar === "");
            if (!parsedDate && !isNaN(middleNumber) && isCurrentCharParsable && this.autoCorrectParts) {
                if (symbol === MONTH_SYMBOL && !month) {
                    // JS months start from 0 (January) instead of 1 (January)
                    const monthNumber = middleNumber - JS_MONTH_OFFSET;
                    if (monthNumber > -1 && monthNumber < 12) {
                        parsedDate = cloneDate(this.value);
                        parsedDate.setMonth(monthNumber);
                        if (parsedDate.getMonth() !== monthNumber) {
                            parsedDate = lastDayOfMonth(addMonths(parsedDate, -1));
                        }
                    }
                }
                if (symbol === 'y') {
                    parsedDate = createDate(parseInt(middle, 10), this.month ? this.value.getMonth() : 0, this.date ? this.value.getDate() : 1, this.hours ? this.value.getHours() : 0, this.minutes ? this.value.getMinutes() : 0, this.seconds ? this.value.getSeconds() : 0, this.milliseconds ? this.value.getMilliseconds() : 0);
                    if (((isInCaretMode && isValidDate(parsedDate)) ||
                        (!isInCaretMode && parsedDate)) && this.date && parsedDate.getDate() !== this.value.getDate()) {
                        parsedDate = lastDayOfMonth(addMonths(parsedDate, -1));
                    }
                }
            }
            if ((isInCaretMode && isValidDate(parsedDate)) || (!isInCaretMode && parsedDate)) {
                // move to next segment if the part will overflow with next char
                // when start from empty date (01, then 010), padded zeros should be trimmed
                const peekResult = this.isPeekDateOverflowingDatePart({
                    useBasePrefixAndSuffix: autoCorrectedPrefixAndSuffix,
                    middle,
                    patternValue,
                    basePrefix,
                    baseSuffix,
                    prefix,
                    suffix,
                    symbol,
                    patternLength,
                    leadingZero
                });
                let switchToNext = peekResult.switchToNext;
                if (this.shouldNormalizeCentury()) {
                    parsedDate = this.normalizeCentury(parsedDate);
                }
                if (symbol === 'H' && parsedDate.getHours() >= 12) {
                    this.setExisting('a', true);
                }
                this._value = parsedDate;
                this.setExisting(symbol, true);
                this.resetInvalidDateSymbol(symbol);
                if (!this.autoCorrectParts) {
                    if (symbol === "M") {
                        if (this.getExisting("M") && this.getExisting("y")) {
                            // changing from 28/Feb to 29/Feb to 29/March
                            this.setExisting("d", true);
                            this.resetInvalidDateSymbol("d");
                        }
                    }
                    else if (symbol === "d") {
                        if (this.getExisting("d") && this.getExisting("y")) {
                            // changing from 31/Jan to 31/Feb to 28/Feb
                            this.setExisting("M", true);
                            this.resetInvalidDateSymbol("M");
                        }
                    }
                    if (!this.hasInvalidDatePart()) {
                        this.markDatePartsAsExisting();
                        if (!peekResult.peekedDate && peekResult.switchToNext && !this.autoCorrectParts) {
                            if (symbol === "M") {
                                // skip processing the month
                            }
                            else if (symbol === "d") {
                                if (peekResult.parsedPeekedValue === 30 &&
                                    this.value.getMonth() === MONTH_INDEX_FEBRUARY) {
                                    // the peekValue cannot be constructed
                                    // as there cannot be more than 29 days in February
                                    // still the segment should not be switched as autoCorrectParts="false"
                                    // should allow typing "30"
                                    switchToNext = false;
                                }
                            }
                        }
                    }
                }
                return extend(parseResult, { value: this.value, switchToNext: switchToNext });
            }
        }
        if (month) {
            parsedDate = this.intl.parseDate(prefix + month + suffix, this.format, this.localeId);
            if (parsedDate) {
                this._value = parsedDate;
                this.setExisting(symbol, true);
                return extend(parseResult, { value: this.value, switchToNext: false });
            }
        }
        if (dayPeriod) {
            parsedDate = this.intl.parseDate(prefix + dayPeriod + suffix, this.format) ||
                this.intl.parseDate(basePrefix + dayPeriod + baseSuffix, this.format);
            if (parsedDate) {
                this._value = parsedDate;
                this.setExisting(symbol, true);
                return extend(parseResult, { value: this.value, switchToNext: true });
            }
        }
        if (isZeroCurrentChar) {
            this.setExisting(symbol, false);
        }
        if (!this.autoCorrectParts) {
            let datePartValue;
            let textToParse = isInCaretMode ? datePartText : middle;
            let parsedValue = parseToInt(textToParse);
            if (isNumber(parsedValue)) {
                if ((symbol === "d" && (parsedValue <= 0 || parsedValue > 31)) ||
                    (symbol === "M" && (parsedValue <= 0 || parsedValue > 11))) {
                    if (isInCaretMode) {
                        return extend(parseResult, {
                            value: null,
                            switchToNext: false
                        });
                    }
                    else {
                        // the value overflows the possible value range
                        // thus reset the segment value regardless of the "resetSegmentValue" flag
                        // otherwise the input is ignored and you cannot change the value,
                        // e.g. "03->(press 2)->02" will not work and the user will be blocked on "03"
                        textToParse = currentChar;
                        parsedValue = parseToInt(textToParse);
                    }
                }
                datePartValue = symbol === "M" ?
                    parsedValue - JS_MONTH_OFFSET :
                    parsedValue;
                const isMonth = symbol === "M";
                const isDay = symbol === "d";
                let newValue = cloneDate(this._value);
                const invalidDateParts = this._partiallyInvalidDate.invalidDateParts || {};
                let year = invalidDateParts.y.value || newValue.getFullYear();
                /* tslint:disable:no-shadowed-variable */
                let month = isMonth ? datePartValue : invalidDateParts.M.value || newValue.getMonth();
                /* tslint:enable:no-shadowed-variable */
                let day = isDay ? datePartValue : invalidDateParts.d.value || invalidDateParts.E.value || newValue.getDate();
                let hour = invalidDateParts.h.value || invalidDateParts.H.value || newValue.getHours();
                let minutes = invalidDateParts.m.value || newValue.getMinutes();
                let seconds = invalidDateParts.s.value || newValue.getSeconds();
                let milliseconds = invalidDateParts.S.value || newValue.getMilliseconds();
                const dateCandidate = createDate(year, month, day, hour, minutes, seconds, milliseconds);
                const dateCandidateExists = areDatePartsEqualTo(dateCandidate, year, month, day, hour, minutes, seconds, milliseconds);
                const newValueCandidate = isMonth || isDay ?
                    this.modifyDateSymbolWithValue(newValue, symbol, isMonth ? month : day) :
                    null;
                let invalidDateFound = false;
                if (isMonth && newValueCandidate) {
                    if (newValueCandidate.getMonth() === month) {
                        if (this.getExisting("d")) {
                            if (dateCandidateExists) {
                                newValue = cloneDate(dateCandidate);
                                this.resetInvalidDateSymbol(symbol);
                            }
                            else {
                                invalidDateFound = true;
                                this.setInvalidDatePart(symbol, {
                                    value: month,
                                    date: cloneDate(newValueCandidate),
                                    startDate: cloneDate(this.value)
                                });
                                this.setExisting(symbol, false);
                            }
                        }
                        else if (dateCandidateExists) {
                            this.resetInvalidDateSymbol(symbol);
                            newValue = cloneDate(dateCandidate);
                            if (this.getExisting("M") && this.getExisting("y")) {
                                // changing from 28/Feb to 29/Feb to 29/March
                                this.setExisting("d", true);
                                this.resetInvalidDateSymbol("d");
                            }
                        }
                        else {
                            this.resetInvalidDateSymbol(symbol);
                            newValue = cloneDate(newValueCandidate);
                        }
                    }
                    else {
                        invalidDateFound = true;
                        this.setInvalidDatePart(symbol, {
                            value: month,
                            date: cloneDate(newValueCandidate),
                            startDate: cloneDate(this.value)
                        });
                        this.setExisting(symbol, false);
                    }
                }
                else if (isDay && newValueCandidate) {
                    if (newValueCandidate.getDate() === day) {
                        if (this.getExisting("M")) {
                            if (dateCandidateExists) {
                                newValue = cloneDate(dateCandidate);
                                this.resetInvalidDateSymbol(symbol);
                            }
                            else {
                                invalidDateFound = true;
                                this.setInvalidDatePart(symbol, {
                                    value: day,
                                    date: cloneDate(newValueCandidate),
                                    startDate: cloneDate(this.value)
                                });
                                this.setExisting(symbol, false);
                            }
                        }
                        else if (dateCandidateExists) {
                            newValue = cloneDate(dateCandidate);
                            this.resetInvalidDateSymbol(symbol);
                            if (this.getExisting("d") && this.getExisting("y")) {
                                // changing from 31/Jan to 31/Feb to 28/Feb
                                this.setExisting("M", true);
                                this.resetInvalidDateSymbol("M");
                            }
                        }
                        else {
                            this.resetInvalidDateSymbol(symbol);
                            newValue = cloneDate(newValueCandidate);
                        }
                    }
                    else {
                        invalidDateFound = true;
                        this.setInvalidDatePart(symbol, {
                            value: day,
                            date: cloneDate(this.value),
                            startDate: cloneDate(this.value)
                        });
                        this.setExisting(symbol, false);
                    }
                }
                if (!invalidDateFound) {
                    this.setExisting(symbol, true);
                    if (isInCaretMode && !isValidDate(parsedDate)) {
                        const valueCandidate = this.intl.parseDate(basePrefix + middle + baseSuffix, this.format, this.localeId);
                        if (isValidDate(valueCandidate)) {
                            this._value = valueCandidate;
                        }
                    }
                    else {
                        this._value = newValue;
                    }
                    if (this.getValue()) {
                        this.resetInvalidDate();
                    }
                }
                let switchToNext = false;
                if (symbol === "M") {
                    if (parsedValue >= 2 || textToParse.length >= 2) {
                        switchToNext = true;
                    }
                    else {
                        switchToNext = false;
                    }
                }
                else {
                    if (hasFixedFormat) {
                        const peekDateSwitchToNext = this.isPeekDateOverflowingDatePart({
                            useBasePrefixAndSuffix: !this.autoCorrectParts,
                            middle,
                            patternValue,
                            basePrefix,
                            baseSuffix,
                            prefix,
                            suffix,
                            symbol,
                            patternLength,
                            leadingZero
                        }).switchToNext;
                        switchToNext = peekDateSwitchToNext;
                    }
                    else {
                        switchToNext = textToParse.length > segmentLength;
                    }
                }
                return extend(parseResult, {
                    value: null,
                    switchToNext: switchToNext,
                    hasInvalidDatePart: invalidDateFound
                });
            }
        }
        return extend(parseResult, { value: null, switchToNext: false });
    }
    /**
     * @hidden
     */
    symbolMap(symbol) {
        return this.intl.splitDateFormat(this.format, this.localeId).reduce(dateSymbolMap, {})[symbol];
    }
    /**
     * @hidden
     */
    resetLeadingZero() {
        const hasLeadingZero = this.leadingZero !== null;
        this.setLeadingZero(null);
        return hasLeadingZero;
    }
    setLeadingZero(leadingZero) {
        this.leadingZero = leadingZero;
    }
    /**
     * @hidden
     */
    getLeadingZero() {
        return this.leadingZero || {};
    }
    /**
     * @hidden
     */
    normalizeCentury(date) {
        if (!isPresent(date)) {
            return date;
        }
        const twoDigitYear = cropTwoDigitYear(date);
        const centuryBase = this.getNormalizedCenturyBase(twoDigitYear);
        const normalizedDate = setYears(date, centuryBase + twoDigitYear);
        return normalizedDate;
    }
    incrementLeadingZero(symbol) {
        const leadingZero = this.leadingZero || {};
        leadingZero[symbol] = (leadingZero[symbol] || 0) + 1;
        this.leadingZero = leadingZero;
    }
    /**
     * @hidden
     */
    isAbbrMonth(parts, symbol) {
        const pattern = this.partPattern(parts, symbol);
        return pattern.type === 'month' && pattern.names;
    }
    /**
     * @hidden
     */
    partPattern(parts, symbol) {
        return parts.filter((part) => part.pattern.indexOf(symbol) !== -1)[0];
    }
    /**
     * @hidden
     */
    peek(value, pattern) {
        const peekValue = value.replace(/^0*/, '') + '0';
        return padZero(pattern.length - peekValue.length) + peekValue;
    }
    /**
     * @hidden
     */
    matchMonth(typedChar) {
        this.typedMonthPart += typedChar.toLowerCase();
        if (this.monthNames.length === 0) {
            return '';
        }
        while (this.typedMonthPart.length > 0) {
            for (let i = 0; i < this.monthNames.length; i++) {
                if (this.monthNames[i].toLowerCase().indexOf(this.typedMonthPart) === 0) {
                    return this.monthNames[i];
                }
            }
            const monthAsNum = parseInt(this.typedMonthPart, 10);
            /* ensure they exact match */
            if (monthAsNum >= 1 && monthAsNum <= 12 && monthAsNum.toString() === this.typedMonthPart) {
                return this.monthNames[monthAsNum - 1];
            }
            this.typedMonthPart = this.typedMonthPart.substring(1, this.typedMonthPart.length);
        }
        return '';
    }
    /**
     * @hidden
     */
    matchDayPeriod(typedChar, symbol) {
        const lowerChart = typedChar.toLowerCase();
        if (symbol === 'a' && this.dayPeriods) {
            if (this.dayPeriods.am.toLowerCase().startsWith(lowerChart)) {
                return this.dayPeriods.am;
            }
            else if (this.dayPeriods.pm.toLowerCase().startsWith(lowerChart)) {
                return this.dayPeriods.pm;
            }
        }
        return '';
    }
    /**
     * @hidden
     */
    allFormattedMonths(locale = "en") {
        const dateFormatParts = this.intl.splitDateFormat(this.format, this.localeId);
        for (let i = 0; i < dateFormatParts.length; i++) {
            if (dateFormatParts[i].type === 'month' && dateFormatParts[i].names) {
                return this.intl.dateFormatNames(locale, dateFormatParts[i].names);
            }
        }
        return [];
    }
    /**
     * @hidden
     */
    allDayPeriods(locale = "en") {
        const dateFormatParts = this.intl.splitDateFormat(this.format);
        for (let i = 0; i < dateFormatParts.length; i++) {
            if (dateFormatParts[i].type === "dayperiod" && dateFormatParts[i].names) {
                return this.intl.dateFormatNames(locale, dateFormatParts[i].names);
            }
        }
        return null;
    }
    /**
     * @hidden
     */
    patternLength(pattern) {
        if (pattern[0] === 'y') {
            return 4;
        }
        if (SHORT_PATTERN_LENGTH_REGEXP.test(pattern)) {
            return 2;
        }
        return 0;
    }
    /**
     * @hidden
     */
    dateFormatString(date, format) {
        const dateFormatParts = this.intl.splitDateFormat(format, this.localeId);
        const parts = [];
        const partMap = [];
        for (let i = 0; i < dateFormatParts.length; i++) {
            let partLength = this.intl.formatDate(date, { pattern: dateFormatParts[i].pattern }, this.localeId).length;
            while (partLength > 0) {
                parts.push(this.symbols[dateFormatParts[i].pattern[0]] || Constants.formatSeparator);
                partMap.push(dateFormatParts[i]);
                partLength--;
            }
        }
        const returnValue = new Mask();
        returnValue.symbols = parts.join('');
        returnValue.partMap = partMap;
        return returnValue;
    }
    /**
     * @hidden
     */
    merge(text, mask) {
        // Important: right to left.
        let resultText = '';
        let resultFormat = '';
        let format = mask.symbols;
        let processTextSymbolsEnded = false;
        let ignoreFormatSymbolsCount = 0;
        const formattedDates = this.getFormattedInvalidDates(format);
        for (let formatSymbolIndex = format.length - 1; formatSymbolIndex >= 0; formatSymbolIndex--) {
            const partsForSegment = this.getPartsForSegment(mask, formatSymbolIndex);
            if (this.knownParts.indexOf(format[formatSymbolIndex]) === -1 || this.getExisting(format[formatSymbolIndex])) {
                if (this.autoCorrectParts) {
                    resultText = text[formatSymbolIndex] + resultText;
                }
                else {
                    if (text.length !== format.length) {
                        if (processTextSymbolsEnded) {
                            resultText = text[formatSymbolIndex] + resultText;
                        }
                        else if (ignoreFormatSymbolsCount > 0) {
                            resultText = text[formatSymbolIndex] + resultText;
                            ignoreFormatSymbolsCount--;
                            if (ignoreFormatSymbolsCount <= 0) {
                                processTextSymbolsEnded = true;
                            }
                        }
                        else {
                            resultText = (text[formatSymbolIndex + text.length - format.length] || "") + resultText;
                        }
                    }
                    else {
                        resultText = text[formatSymbolIndex] + resultText;
                    }
                }
                resultFormat = format[formatSymbolIndex] + resultFormat;
            }
            else {
                const symbol = format[formatSymbolIndex];
                let formatSymbolIndexModifier = 0;
                if (this.autoCorrectParts || (!this.autoCorrectParts && !this.getInvalidDatePartValue(symbol))) {
                    while (formatSymbolIndex >= 0 && symbol === format[formatSymbolIndex]) {
                        formatSymbolIndex--;
                    }
                    formatSymbolIndex++;
                }
                if (this.leadingZero && this.leadingZero[symbol]) {
                    resultText = '0' + resultText;
                }
                else {
                    if (!this.autoCorrectParts && this.getInvalidDatePartValue(symbol)) {
                        let datePartText = this.getInvalidDatePartValue(symbol).toString();
                        if (symbol === "M") {
                            datePartText = (parseToInt(this.getInvalidDatePartValue(symbol)) + JS_MONTH_OFFSET).toString();
                            if (partsForSegment.length > MONTH_PART_WITH_WORDS_THRESHOLD) {
                                resultText = formattedDates[symbol][formatSymbolIndex] + resultText;
                            }
                            else {
                                datePartText = (parseToInt(this.getInvalidDatePartValue(symbol)) + JS_MONTH_OFFSET).toString();
                                const formattedDatePart = padZero(partsForSegment.length - datePartText.length) + datePartText;
                                resultText = formattedDatePart + resultText;
                                formatSymbolIndexModifier = partsForSegment.length - 1;
                                ignoreFormatSymbolsCount = datePartText.length - partsForSegment.length;
                            }
                        }
                        else {
                            const formattedDatePart = padZero(partsForSegment.length - datePartText.length) + datePartText;
                            resultText = formattedDatePart + resultText;
                            formatSymbolIndexModifier = partsForSegment.length - 1;
                            ignoreFormatSymbolsCount = datePartText.length - partsForSegment.length;
                        }
                    }
                    else {
                        resultText = this.dateFieldName(mask.partMap[formatSymbolIndex]) + resultText;
                    }
                }
                while (resultFormat.length < resultText.length) {
                    resultFormat = format[formatSymbolIndex] + resultFormat;
                }
                if (formatSymbolIndexModifier !== 0) {
                    formatSymbolIndex = (formatSymbolIndex - formatSymbolIndexModifier) + (text.length - format.length);
                }
            }
        }
        return { text: resultText, format: resultFormat };
    }
    /**
     * @hidden
     */
    dateFieldName(part) {
        const formatPlaceholder = this.formatPlaceholder || 'wide';
        if (formatPlaceholder[part.type]) {
            return formatPlaceholder[part.type];
        }
        if (formatPlaceholder === 'formatPattern') {
            return part.pattern;
        }
        return this.intl.dateFieldName(Object.assign(part, { nameType: formatPlaceholder }));
    }
    /**
     * @hidden
     */
    getNormalizedCenturyBase(twoDigitYear) {
        return twoDigitYear > this.twoDigitYearMax ?
            PREVIOUS_CENTURY_BASE :
            CURRENT_CENTURY_BASE;
    }
    /**
     * @hidden
     */
    shouldNormalizeCentury() {
        return this.intl.splitDateFormat(this.format).some(part => part.pattern === 'yy');
    }
    resetInvalidDate() {
        this._partiallyInvalidDate.startDate = null;
        Object.keys(this._partiallyInvalidDate.invalidDateParts).forEach(key => {
            this.resetInvalidDatePart(key);
        });
    }
    resetInvalidDateSymbol(symbol) {
        this.resetInvalidDatePart(symbol);
        let shouldResetInvalidDate = true;
        Object.keys(this._partiallyInvalidDate.invalidDateParts).forEach(key => {
            if (this._partiallyInvalidDate.invalidDateParts[key] &&
                isPresent(this._partiallyInvalidDate.invalidDateParts[key].value)) {
                shouldResetInvalidDate = false;
            }
        });
        if (shouldResetInvalidDate) {
            this.resetInvalidDate();
        }
    }
    resetInvalidDatePart(symbol) {
        if (this._partiallyInvalidDate.invalidDateParts[symbol]) {
            this._partiallyInvalidDate.invalidDateParts[symbol] = {
                value: null,
                date: null,
                startDateOffset: 0
            };
        }
    }
    /**
     * @hidden
     */
    getInvalidDatePart(symbol) {
        const invalidDatePart = this._partiallyInvalidDate.invalidDateParts[symbol];
        return invalidDatePart || {};
    }
    /**
     * @hidden
     */
    getInvalidDatePartValue(symbol) {
        const invalidDatePart = this._partiallyInvalidDate.invalidDateParts[symbol];
        return (invalidDatePart || {}).value;
    }
    setInvalidDatePart(symbol, { value = null, date = null, startDateOffset = 0, startDate = null }) {
        if (this._partiallyInvalidDate.invalidDateParts[symbol]) {
            this._partiallyInvalidDate.invalidDateParts[symbol].value = value;
            this._partiallyInvalidDate.invalidDateParts[symbol].date = date;
            this._partiallyInvalidDate.invalidDateParts[symbol].startDateOffset = startDateOffset;
            this._partiallyInvalidDate.startDate = startDate;
        }
    }
    /**
     * @hidden
     */
    hasInvalidDatePart() {
        let hasInvalidDatePart = false;
        Object.keys(this._partiallyInvalidDate.invalidDateParts).forEach(key => {
            if (this._partiallyInvalidDate.invalidDateParts[key] &&
                isPresent(this._partiallyInvalidDate.invalidDateParts[key].value)) {
                hasInvalidDatePart = true;
            }
        });
        return hasInvalidDatePart;
    }
    /**
     * @hidden
     */
    modifyDateSymbolWithOffset(date, symbol, offset) {
        let newValue = cloneDate(date);
        let timeModified = false;
        switch (symbol) {
            case 'y':
                newValue.setFullYear(newValue.getFullYear() + offset);
                break;
            case 'M':
                newValue = addMonths(this.value, offset);
                break;
            case 'd':
            case 'E':
                newValue.setDate(newValue.getDate() + offset);
                break;
            case 'h':
            case 'H':
                newValue.setHours(newValue.getHours() + offset);
                timeModified = true;
                break;
            case 'm':
                newValue.setMinutes(newValue.getMinutes() + offset);
                timeModified = true;
                break;
            case 's':
                newValue.setSeconds(newValue.getSeconds() + offset);
                timeModified = true;
                break;
            case "S":
                newValue.setMilliseconds(newValue.getMilliseconds() + offset);
                break;
            case 'a':
                newValue.setHours(newValue.getHours() + (12 * offset));
                timeModified = true;
                break;
            default: break;
        }
        return {
            date: newValue,
            timeModified: timeModified
        };
    }
    /**
     * @hidden
     */
    modifyDateSymbolWithValue(date, symbol, value) {
        let newValue = cloneDate(date);
        switch (symbol) {
            case 'y':
                newValue.setFullYear(value);
                break;
            case 'M':
                newValue = addMonths(date, value - date.getMonth());
                break;
            case 'd':
            case 'E':
                newValue.setDate(value);
                break;
            case 'h':
            case 'H':
                newValue.setHours(value);
                break;
            case 'm':
                newValue.setMinutes(value);
                break;
            case 's':
                newValue.setSeconds(value);
                break;
            case "S":
                newValue.setMilliseconds(value);
                break;
            case 'a':
                newValue.setHours(value);
                break;
            default: break;
        }
        return newValue;
    }
    markDatePartsAsExisting() {
        this.modifyExisting(true);
    }
    /**
     * @hidden
     */
    getPartsForSegment(mask, partIndex) {
        const segmentPart = mask.partMap[partIndex];
        const partsForSegment = [];
        for (let maskPartIndex = partIndex; maskPartIndex < mask.partMap.length; maskPartIndex++) {
            const part = mask.partMap[maskPartIndex];
            if (segmentPart.type === part.type && segmentPart.pattern === part.pattern) {
                partsForSegment.push(part);
            }
            else {
                break;
            }
        }
        for (let maskPartIndex = partIndex - 1; maskPartIndex >= 0; maskPartIndex--) {
            const part = mask.partMap[maskPartIndex];
            if (segmentPart.type === part.type && segmentPart.pattern === part.pattern) {
                partsForSegment.unshift(part);
            }
            else {
                break;
            }
        }
        return partsForSegment;
    }
    /**
     * @hidden
     */
    isPeekDateOverflowingDatePart({ useBasePrefixAndSuffix, middle, patternValue, basePrefix, baseSuffix, prefix, suffix, symbol, patternLength, leadingZero }) {
        // move to next segment if the part will overflow with next char
        // when start from empty date (01, then 010), padded zeros should be trimmed
        const peekedValue = this.peek(middle, patternValue);
        const peekedDateString = useBasePrefixAndSuffix ?
            `${basePrefix}${peekedValue}${baseSuffix}` :
            `${prefix}${peekedValue}${suffix}`;
        const peekedDate = this.intl.parseDate(peekedDateString, this.format, this.localeId);
        const leadingZeroOffset = (this.leadingZero || {})[symbol] || 0;
        const patternSatisfied = (leadingZeroOffset + unpadZero(middle).length) >= patternLength;
        let parsedPeekedValue = parseToInt(peekedValue);
        if (symbol === "M") {
        }
        else if (symbol === "d") {
        }
        const switchToNext = peekedDate === null ||
            (leadingZero[symbol] ?
                patternValue.length <= middle.length :
                patternSatisfied);
        return {
            peekedDate,
            peekedDateString,
            peekedValue,
            parsedPeekedValue,
            switchToNext
        };
    }
}
