import { Component, OnInit } from '@angular/core'
import { ActivatedRoute } from '@angular/router'
import {
  CommonService,
  DashboardDeviceStatusPopupComponent,
  DashboardDTRStatus,
  FeederType,
} from '@app/index'
import { DashboardDTRData } from '@app/models'
import { DashboardDTR, GetFeederAreaListBasedOnFeederType } from '@app/store'
import { Store } from '@ngxs/store'
import { DialogService } from '@progress/kendo-angular-dialog'
import { NgxSpinnerService } from 'ngx-spinner'
import { interval, Subject, takeUntil, timer } from 'rxjs'
import { ToastrService } from 'ngx-toastr'

@Component({
  selector: 'app-dashboard-dtr',
  templateUrl: './dashboard-dtr.component.html',
  styleUrl: './dashboard-dtr.component.scss',
})
export class DashboardDtrComponent implements OnInit {
  triggerGetUpdatedTime: Subject<any> = new Subject<any>()
  dashboardDtrCardData: DashboardDTRData | undefined
  dasboardDtrStatus = DashboardDTRStatus
  selectedFeederAreaId: any
  feederList: any
  dashboardType: any
  isDTR = true

  private destroy$ = new Subject<void>()

  constructor(
    private store: Store,
    private route: ActivatedRoute,
    private spinner: NgxSpinnerService,
    private dialogService: DialogService,
    private toastr: ToastrService,
    public commonService: CommonService,
  ) {}

  ngOnInit(): void {
    this.route.data.subscribe((data) => {
      this.isDTR = data['isDTR']
      this.dashboardType = this.isDTR ? FeederType.DTR : FeederType.PTR
      this.GetFeederList()
      this.setupInterval()
    })
  }

  setupInterval() {
    interval(60000)
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        this.GetFeederList()
      })
  }

  GetFeederList(): void {
    this.store
      .dispatch(new GetFeederAreaListBasedOnFeederType(this.isDTR))
      .subscribe((res) => {
        if (res.common.feederAreaListByFeederType.length > 0) {
          this.feederList = res.common.feederAreaListByFeederType
          this.selectedFeederAreaId = { key: 'All', value: 'All' }
          this.getDashboardDTRData()
        }
      })
  }

  getDashboardDTRData(): void {
    this.spinner.show()
    this.store
      .dispatch(
        new DashboardDTR(this.selectedFeederAreaId.key, this.dashboardType),
      )
      .subscribe(
        (res: any) => {
          this.spinner.hide()
          if (res.dashboarddtr && res.dashboarddtr.dashboardDtrData) {
            this.dashboardDtrCardData = res.dashboarddtr.dashboardDtrData
          }
        },
        (error) => {
          this.spinner.hide()
        },
      )
  }

  onFeederChange() {
    this.getDashboardDTRData()
  }

  onDashboardDTRStatusClick(
    dashboardDtrStatus: number,
    deviceCount: any,
    statusName: string,
  ): void {
    if (deviceCount == 0) {
      this.toastr.error(`No ${statusName} Found`)
      return
    }
    const dialogRef = this.dialogService.open({
      content: DashboardDeviceStatusPopupComponent,
      width: 800,
    })
    const dasboardDeviceStatusPopupInfo: any = dialogRef.content
      .instance as DashboardDeviceStatusPopupComponent

    dasboardDeviceStatusPopupInfo.dashboardDtrStatus = dashboardDtrStatus
    dasboardDeviceStatusPopupInfo.statusName = statusName
    dasboardDeviceStatusPopupInfo.isDTR = this.isDTR
    dasboardDeviceStatusPopupInfo.feederAreaId = this.selectedFeederAreaId
    dialogRef.result.subscribe((res: any) => {
      if (res && res.confirmed) {
      }
    })
  }

  ngOnDestroy() {
    this.destroy$.next()
    this.destroy$.complete()
  }
}
