import { Component, OnInit, Optional, ViewChild } from '@angular/core'
import { FormControl, FormGroup, Validators } from '@angular/forms'
import { ActivatedRoute, Router } from '@angular/router'
import { SVGIcon, plusIcon } from '@progress/kendo-svg-icons'
import { ActionTypeEnum, ImageType } from '@app/enums'
import { OtpDialogComponent } from '@app/components'
import { NgxCroppedEvent, NgxPhotoEditorService } from 'ngx-photo-editor'
import { CommonService } from '@app/services'
import { ToastrService } from 'ngx-toastr'
import { NotificationMessage } from '@app/enums/notification'
import { Observable, switchMap } from 'rxjs'
import { Select, Store } from '@ngxs/store'
import {
  AddDevice,
  CheckActivationDeviceExistsByDeviceId,
  CommonState,
  DownloadFileUpload,
  GetDeviceDetailByDeviceId,
  GetDeviceIdByFeeder,
  GetFeederAreaList,
  ImageFileUpload,
  SendOtp,
  UpdateDeviceByDeviceId,
} from '@app/store'
import { NgxSpinnerService } from 'ngx-spinner'
import { DialogRef, DialogService } from '@progress/kendo-angular-dialog'
import { Lightbox } from 'ngx-lightbox'
import { noSpacesOrSpecialCharsValidator } from '@app/directive'
import { Roles } from '@app/enums/roles'
import swal from 'sweetalert'

@Component({
  selector: 'app-add-device',
  templateUrl: './add-device.component.html',
  styleUrl: './add-device.component.scss',
})
export class AddDeviceListComponent implements OnInit {
  @ViewChild('fileInput') fileInput: any

  @Select(CommonState.getFeederAreaList)
  feederAreaList$?: Observable<any>

  imageType = ImageType
  ngCropImageOutput?: NgxCroppedEvent

  headerText: string = ''
  submitButtonText: string = ''

  deviceForm: FormGroup | any
  deviceId: any
  imageTypeId: any
  feederId: any

  feederArray: any[] = []
  deviceImageList: any[] = []

  isAddMode = true

  plusSVG: SVGIcon = plusIcon
  rolesList = Roles
  locationDetails: any
  currentUserDetails: any
  constructor(
    private _Activatedroute: ActivatedRoute,
    private router: Router,
    private service: NgxPhotoEditorService,
    public commonService: CommonService,
    private toastr: ToastrService,
    private store: Store,
    private spinner: NgxSpinnerService,
    private dialogService: DialogService,
    @Optional() private dialogRef: DialogRef,
    private _lightbox: Lightbox,
  ) {
    this._Activatedroute.paramMap.subscribe((params: any) => {
      if (params.keys.length > 0) {
        this.isAddMode = atob(params.get('isAddMode')) === 'true' ? true : false

        this.deviceId = atob(params.get('deviceId'))

        if (!this.isAddMode) {
          this.getDeviceDetailByDeviceId()
        }

        this.setOnLoadData(this.isAddMode)
      } else {
        this.currentUserDetails = this.commonService.getLoggedInUserDetails()
        if (
          this.isAddMode &&
          +this.currentUserDetails.RoleId === Roles.TechnicianLevelRole
        ) {
          this.getLocationDetails()
        }
      }
    })
    this.setForm()
  }

  ngOnInit(): void {
    //Called after the constructor, initializing input properties, and the first call to ngOnChanges.
    //Add 'implements OnInit' to the class.

    this.feederAreaList$?.subscribe((res) => {
      this.feederArray = res
    })
  }

  getLocationDetails(): void {
    this.getCurrentLocation()
      .then((coords) => {
        return this.getGeocode(coords.lat, coords.lng)
      })
      .then((details) => {
        this.locationDetails = details
        console.log(this.locationDetails, 'locationDetails')
        this.deviceForm.patchValue({
          street: this.locationDetails.street ?? '',
          area: this.locationDetails.area ?? '',
          city: this.locationDetails.city_town ?? '',
          district: this.locationDetails.district ?? '',
          state: this.locationDetails.state ?? '',
          pincode: this.locationDetails.pin_code ?? '',
        })
      })
      .catch((error) => {
        console.error('Error getting location details:', error)
      })
  }

  getCurrentLocation(): Promise<google.maps.LatLngLiteral> {
    return new Promise((resolve, reject) => {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            resolve({
              lat: position.coords.latitude,
              lng: position.coords.longitude,
            })
          },
          (error) => {
            reject(error)
          },
          {
            enableHighAccuracy: true, // Request high accuracy
            timeout: 10000, // Set a timeout
            maximumAge: 0, // Disable caching
          },
        )
      } else {
        reject(new Error('Geolocation is not supported by this browser.'))
      }
    })
  }

  getGeocode(lat: number, lng: number): Promise<any> {
    return new Promise((resolve, reject) => {
      const geocoder = new google.maps.Geocoder()
      const latlng = { lat, lng }
      geocoder.geocode({ location: latlng }, (results: any, status) => {
        if (status === 'OK') {
          if (results[0]) {
            //resolve(results[0])
            resolve(this.parseAddressComponents(results[0].address_components))
          } else {
            reject(new Error('No results found'))
          }
        } else {
          reject(new Error('Geocoder failed due to: ' + status))
        }
      })
    })
  }

  parseAddressComponents(components: google.maps.GeocoderAddressComponent[]) {
    const address: any = {}
    components.forEach((component) => {
      component.types.forEach((type) => {
        switch (type) {
          case 'sublocality_level_3':
            address.street = component.long_name
            break
          case 'route':
            address.line = component.long_name
            break
          case 'sublocality_level_2':
            address.area = component.long_name
            break
          case 'locality':
            address.city_town = component.long_name
            break
          case 'administrative_area_level_3':
            address.district = component.long_name
            break
          case 'administrative_area_level_1':
            address.state = component.long_name
            break
          case 'country':
            address.country = component.long_name
            break
          case 'postal_code':
            address.pin_code = component.long_name
            break
        }
      })
    })
    return address
  }

  setOnLoadData(isAddMode: boolean): void {
    this.headerText = isAddMode ? 'Create a new Device' : 'Update Device'
    this.submitButtonText = isAddMode ? 'Create Device' : 'Update Device'
  }

  setForm(): void {
    this.deviceForm = new FormGroup({
      deviceId: new FormControl('', [
        Validators.required,
        Validators.maxLength(5000),
        noSpacesOrSpecialCharsValidator(),
      ]),
      deviceName: new FormControl('', [noSpacesOrSpecialCharsValidator()]),
      newName: new FormControl(''),
      ratedKva: new FormControl(''),
      ctRatio: new FormControl(''),
      latLong: new FormControl('', [noSpacesOrSpecialCharsValidator()]),
      staticGPS: new FormControl(false),
      street: new FormControl('', [noSpacesOrSpecialCharsValidator()]),
      area: new FormControl('', [noSpacesOrSpecialCharsValidator()]),
      city: new FormControl('', [noSpacesOrSpecialCharsValidator()]),
      district: new FormControl('', [noSpacesOrSpecialCharsValidator()]),
      state: new FormControl('', [noSpacesOrSpecialCharsValidator()]),
      pincode: new FormControl('', [Validators.maxLength(10)]),
      country: new FormControl('India'),
    })
  }

  getDeviceDetailByDeviceId() {
    this.spinner.show()
    this.store.dispatch(new GetDeviceDetailByDeviceId(this.deviceId)).subscribe(
      (res) => {
        const deviceDetail = res.device.deviceDetailByDeviceId
        this.deviceImageList = deviceDetail.deviceImages.map((x: any) => ({
          ...x,
          src: x.base64Value,
        }))

        this.deviceForm.patchValue({
          deviceId: deviceDetail.deviceId,
          deviceName: deviceDetail.name,
          newName: deviceDetail.name,
          ratedKva: deviceDetail.kva,
          ctRatio: deviceDetail.ctRatio,
          latLong: deviceDetail.gpsLocation,
          staticGPS: deviceDetail.gpsStaticEnabled !== 0,
          street: deviceDetail.deviceAddress.street,
          area: deviceDetail.deviceAddress.area,
          city: deviceDetail.deviceAddress.city,
          district: deviceDetail.deviceAddress.district,
          state: deviceDetail.deviceAddress.state,
          pincode: deviceDetail.deviceAddress.pincode,
          country: deviceDetail.deviceAddress.country,
        })
        this.spinner.hide()
      },
      (error) => {
        this.spinner.hide()
      },
    )
  }

  onCancel(): void {
    this.redirectToDeviceList(false)
  }

  onSelectFile(event: any): void {
    const file = event.target.files[0]
    if (file.type === 'image/jpeg' || file.type === 'image/png') {
      this.bindImageToEditor(file)
    } else {
      this.toastr.error(NotificationMessage.imageInputNotValidMsg)
    }
  }

  bindImageToEditor(file: any): void {
    this.service
      .open(event, {
        aspectRatio: 4 / 3,
        autoCropArea: 1,
      })
      .subscribe((data) => {
        this.ngCropImageOutput = data
        const deviceId = this.deviceForm.get('deviceId').value
        this.spinner.show()
        this.store.dispatch(new ImageFileUpload(deviceId, file)).subscribe(
          (res) => {
            this.spinner.hide()
            const uploadedImageDetails = res.device.uploadedImageDetails
            this.deviceImageList.push({
              ...uploadedImageDetails,
              src: uploadedImageDetails.base64Value,
            })
          },
          (error) => {
            this.spinner.hide()
          },
        )
        this.fileInput.nativeElement.value = ''
      })
  }

  sendOtp(): void {
    const address = {
      street:
        this.deviceForm.get('street').value === null
          ? ''
          : this.deviceForm.get('street').value,
      area:
        this.deviceForm.get('area').value === null
          ? ''
          : this.deviceForm.get('area').value,
      city:
        this.deviceForm.get('city').value === null
          ? ''
          : this.deviceForm.get('city').value,
      district:
        this.deviceForm.get('district').value === null
          ? ''
          : this.deviceForm.get('district').value,
      state:
        this.deviceForm.get('state').value === null
          ? ''
          : this.deviceForm.get('state').value,
      pincode: this.deviceForm.get('pincode').value,
      country:
        this.deviceForm.get('country').value === null
          ? 'India'
          : this.deviceForm.get('country').value,
    }
    const param = {
      devices: this.deviceForm.get('deviceId').value,
      devices_name: this.deviceForm.get('deviceName').value,
      deviceAddress:
        +this.currentUserDetails.RoleId === Roles.TechnicianLevelRole
          ? address
          : null,
    }

    this.store.dispatch(new SendOtp()).subscribe(
      (res) => {
        this.toastr.success(NotificationMessage.otpSendSuccessMsg)
        this.onOpenOtpVerificationDialog(ActionTypeEnum.Add, param)
      },
      (error) => {
        this.spinner.hide()
      },
    )
  }

  onDeviceAdd() {
    let textMessage = `You want to add a new Device - ${this.deviceForm.get('deviceId').value}.`

    swal({
      title: 'Are you sure ?',
      text: textMessage,
      icon: 'warning',
      buttons: {
        cancel: {
          text: 'Cancel',
          visible: true,
          closeModal: true,
        },
        confirm: {
          text: 'Yes',
        },
      },
      dangerMode: true,
    }).then((confirmed) => {
      if (confirmed) {
        const params = {
          devices: this.deviceForm.get('deviceId').value,
          devices_name: this.deviceForm.get('deviceName').value,
        }
        this.spinner.show()
        this.store.dispatch(new AddDevice(params)).subscribe(
          (res) => {
            if (res) {
              this.spinner.hide()
              this.toastr.success(NotificationMessage.DeviceCreatedSuccessMsg)
              this.redirectToDeviceList(true)
            }
          },
          (error) => {
            this.spinner.hide()
          },
        )
      }
    })
  }

  onDeviceSubmit(): void {
    if (this.isAddMode) {
      let textMessage = `You want to add a new Device - ${this.deviceForm.get('deviceId').value}.`

      swal({
        title: 'Are you sure ?',
        text: textMessage,
        icon: 'warning',
        buttons: {
          cancel: {
            text: 'Cancel',
            visible: true,
            closeModal: true,
          },
          confirm: {
            text: 'Yes',
          },
        },
        dangerMode: true,
      }).then((confirmed) => {
        if (confirmed) {
          this.spinner.show()

          this.store
            .dispatch(
              new CheckActivationDeviceExistsByDeviceId(
                this.deviceForm.get('deviceId').value,
              ),
            )
            .subscribe((res) => {
              if (res.device.isActivationDeviceExists) {
                this.sendOtp()
              } else {
                this.toastr.error(NotificationMessage.activationCodeNotFound)
              }
              this.spinner.hide()
            })
        }
      })
    } else {
      let textMessage = `You want to update Device - ${this.deviceForm.get('deviceId').value} ?`

      swal({
        title: 'Are you sure ?',
        text: textMessage,
        icon: 'warning',
        buttons: {
          cancel: {
            text: 'Cancel',
            visible: true,
            closeModal: true,
          },
          confirm: {
            text: 'Yes',
          },
        },
        dangerMode: true,
      }).then((confirmed) => {
        if (confirmed) {
          this.spinner.show()
          this.onDeviceUpdate()
        }
      })
    }
  }

  onDeviceUpdate() {
    const deviceImageList = this.deviceImageList.map((item) => {
      const { src, ...rest } = item
      return rest
    })

    const param = {
      updatedDeviceName: this.deviceForm.get('deviceName').value,
      updateGpsLocation: this.deviceForm.get('latLong').value,
      gpsStaticEnabled: this.deviceForm.get('staticGPS').value === true ? 1 : 0,
      ctRatio: this.deviceForm.get('ctRatio').value,
      deviceImages: deviceImageList,
      deviceAddress: {
        deviceId: this.deviceForm.get('deviceId').value,
        street:
          this.deviceForm.get('street').value === null
            ? ''
            : this.deviceForm.get('street').value,
        area:
          this.deviceForm.get('area').value === null
            ? ''
            : this.deviceForm.get('area').value,
        city:
          this.deviceForm.get('city').value === null
            ? ''
            : this.deviceForm.get('city').value,
        district:
          this.deviceForm.get('district').value === null
            ? ''
            : this.deviceForm.get('district').value,
        state:
          this.deviceForm.get('state').value === null
            ? ''
            : this.deviceForm.get('state').value,
        pincode: this.deviceForm.get('pincode').value,
        country:
          this.deviceForm.get('country').value === null
            ? 'India'
            : this.deviceForm.get('country').value,
      },
    }

    this.store
      .dispatch(new UpdateDeviceByDeviceId(this.deviceId, param))
      .subscribe(
        (res) => {
          this.spinner.hide()
          this.toastr.success(NotificationMessage.DeviceUpdatedSuccessMsg)
          this.redirectToDeviceList(true)
        },
        (error) => {
          this.spinner.hide()
        },
      )
  }

  updateFeederDevice(): void {
    this.store
      .dispatch(new GetFeederAreaList())
      .pipe(
        switchMap((res: any) => this.store.dispatch(new GetDeviceIdByFeeder())),
      )
      .subscribe()
  }

  redirectToDeviceList(isSaved: boolean): void {
    if (this.isAddMode) {
      if (isSaved) {
        this.updateFeederDevice()
      }
      this.dialogRef.close({ isSave: isSaved })
    } else {
      this.updateFeederDevice()
      this.router.navigate(['device'])
    }
  }

  onOpenOtpVerificationDialog(actionType: any, requestBodyParams: any): void {
    const dialogRef = this.dialogService.open({
      content: OtpDialogComponent,
      width: 450,
    })

    this.spinner.hide()
    const otpDialogInfo = dialogRef.content.instance as OtpDialogComponent
    otpDialogInfo.actionType = actionType
    otpDialogInfo.requestBodyParams = requestBodyParams

    dialogRef.result.subscribe((result: any) => {
      if (result.confirmed) {
        this.redirectToDeviceList(true)
      }
    })
  }

  onDeleteImageFromListFile(index: any) {
    this.deviceImageList.splice(index, 1)
  }

  onDownloadImage(fileURL: string): void {
    this.spinner.show()
    const params = {
      fileUrl: fileURL,
    }
    this.store.dispatch(new DownloadFileUpload(params)).subscribe(
      (res) => {
        this.spinner.hide()
        const url = res.device.downloadedUploadFileURL as Blob

        const downloadUrl = window.URL.createObjectURL(url)
        const link = document.createElement('a')
        link.href = downloadUrl

        const uuid = downloadUrl.split('/').pop() as string

        link.download = `${uuid}.png`
        link.click()

        window.URL.revokeObjectURL(downloadUrl)
      },
      (error) => {
        this.spinner.hide()
      },
    )
  }

  onImageOpen(index: any): void {
    this._lightbox.open(this.deviceImageList, index, {
      disableScrolling: true,
      centerVertically: true,
      showDownloadButton: true,
    })
  }

  close(): void {
    this._lightbox.close()
  }
}
