<div class="q-content">
  <div class="d-flex flex-wrap justify-content-between mb-20 gap-15">
    <app-common-header-text
      [triggerGetUpdatedTime]="triggerGetUpdatedTime"
    ></app-common-header-text>
    <kendo-dropdownlist
      [data]="feederList"
      [(ngModel)]="selectedFeederAreaId"
      [textField]="'key'"
      [valueField]="'value'"
      [ngModelOptions]="{ standalone: true }"
      (valueChange)="onFeederChange()"
      class="w-200"
    ></kendo-dropdownlist>
  </div>

  <div class="d-flex-row">
    <div
      class="d-flex-col-xl-2 d-flex-col-lg-4 d-flex-col-md-6 d-flex-col-xs-12 d-flex-col d-flex mb-20"
    >
      <div class="q-card flex-1 volt-boxes border">
        <div class="d-flex justify-content-between align-items-start">
          <div>
            <p class="mb-10 card-title text-secondary">Total Device</p>
            <p class="q-card-value m-0 font-size-24">
              {{ dashboardDtrCardData?.totaL_UNITS }}
            </p>
          </div>
          <div class="q-card-icon q-card-icon-Primary text-align-center">
            <i class="far fa-gauge font-size-20"></i>
          </div>
        </div>
      </div>
    </div>

    <div
      class="d-flex-col-xl-2 d-flex-col-lg-4 d-flex-col-md-6 d-flex-col-xs-12 d-flex-col d-flex mb-20"
    >
      <div class="q-card flex-1 volt-boxes border">
        <div class="d-flex justify-content-between align-items-start">
          <div>
            <p class="mb-10 card-title text-secondary">Installed Device</p>
            <p class="q-card-value m-0 font-size-24">
              {{ dashboardDtrCardData?.installeD_UNITS }}
            </p>
          </div>
          <div class="q-card-icon q-card-icon-Green">
            <i class="far fa-gauge font-size-20"></i>
          </div>
        </div>
      </div>
    </div>

    <div
      class="d-flex-col-xl-2 d-flex-col-lg-4 d-flex-col-md-6 d-flex-col-xs-12 d-flex-col d-flex mb-20"
    >
      <div class="q-card flex-1 volt-boxes border">
        <div class="d-flex justify-content-between align-items-start">
          <div>
            <p class="mb-10 card-title text-secondary">Uninstalled Device</p>
            <p class="q-card-value m-0 font-size-24">
              {{ dashboardDtrCardData?.noT_INSTALLED_UNITS }}
            </p>
          </div>
          <div class="q-card-icon q-card-icon-Red">
            <i class="far fa-gauge font-size-20"></i>
          </div>
        </div>
      </div>
    </div>

    <div
      class="d-flex-col-xl-2 d-flex-col-lg-4 d-flex-col-md-6 d-flex-col-xs-12 d-flex-col d-flex mb-20"
      style="cursor: pointer"
      (click)="
        onDashboardDTRStatusClick(
          dasboardDtrStatus.ActiveDevice,
          dashboardDtrCardData?.acvtivE_DEVICES,
          'Active Device'
        )
      "
    >
      <div class="q-card flex-1 volt-boxes border">
        <div class="d-flex justify-content-between align-items-start">
          <div>
            <p class="mb-10 card-title text-secondary">Active Device</p>
            <p class="q-card-value m-0 font-size-24">
              {{ dashboardDtrCardData?.acvtivE_DEVICES }}
            </p>
          </div>
          <div class="q-card-icon q-card-icon-Green">
            <i class="far fa-check"></i>
          </div>
        </div>
      </div>
    </div>

    <div
      class="d-flex-col-xl-2 d-flex-col-lg-4 d-flex-col-md-6 d-flex-col-xs-12 d-flex-col d-flex mb-20"
      style="cursor: pointer"
      (click)="
        onDashboardDTRStatusClick(
          dasboardDtrStatus.PowerFail,
          dashboardDtrCardData?.poweR_FAIL,
          'Power Fail'
        )
      "
    >
      <div class="q-card flex-1 volt-boxes border">
        <div class="d-flex justify-content-between align-items-start">
          <div>
            <p class="mb-10 card-title text-secondary">Power Fail</p>
            <p class="q-card-value m-0 font-size-24">
              {{ dashboardDtrCardData?.poweR_FAIL }}
            </p>
          </div>
          <div class="q-card-icon q-card-icon-Red">
            <i class="far fa-bolt-slash font-size-20"></i>
          </div>
        </div>
      </div>
    </div>

    <div
      class="d-flex-col-xl-2 d-flex-col-lg-4 d-flex-col-md-6 d-flex-col-xs-12 d-flex-col d-flex mb-20"
      style="cursor: pointer"
      (click)="
        onDashboardDTRStatusClick(
          dasboardDtrStatus.SignalLossDevice,
          dashboardDtrCardData?.comM_LOSS,
          'Signal Loss Device'
        )
      "
    >
      <div class="q-card flex-1 volt-boxes border">
        <div class="d-flex justify-content-between align-items-start">
          <div>
            <p class="mb-10 card-title text-secondary">Signal Loss Device</p>
            <p class="q-card-value m-0 font-size-24">
              {{ dashboardDtrCardData?.comM_LOSS }}
            </p>
          </div>
          <div class="q-card-icon q-card-icon-Red">
            <i class="far fa-signal font-size-20"></i>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="d-flex-row">
    <div
      class="d-flex-col-xl-7 d-flex-col-md-12 d-flex-col-xs-12 d-flex-col d-flex"
    >
      <div class="q-card border pb-0 mb-20 flex-1">
        <div class="d-flex-row mb-20">
          <div class="d-flex-col-xl-3 d-flex-col-lg-12 d-flex-col">
            <p class="font-size-16 mb-20 mt-10 line-height-normal fw-500">
              Phase Overload <br />
              <span class="text-secondary font-size-14 fw-normal"
                >Above 100%</span
              >
            </p>
          </div>
          <div
            class="d-flex-col-xl-9 d-flex-col-lg-12 d-flex flex-wrap gap-15 box-1"
          >
            <div
              class="q-card q-card-bg-phaseR q-card-box"
              style="cursor: pointer"
              (click)="
                onDashboardDTRStatusClick(
                  dasboardDtrStatus.Load_100_R,
                  dashboardDtrCardData?.loaD_100_R,
                  'Phase Overload Above 100% (Phase-R)'
                )
              "
            >
              <div class="d-flex justify-content-center align-items-center">
                <div class="text-align-center">
                  <i
                    class="far fa-gauge-circle-bolt phaseR mb-10 font-size-20"
                  ></i>
                  <p class="q-card-value font-size-20">
                    {{ dashboardDtrCardData?.loaD_100_R }}
                  </p>
                  <p class="m-0 text-secondary">Phase R</p>
                </div>
              </div>
            </div>
            <div
              class="q-card q-card-bg-phaseY q-card-box"
              style="cursor: pointer"
              (click)="
                onDashboardDTRStatusClick(
                  dasboardDtrStatus.Load_100_Y,
                  dashboardDtrCardData?.loaD_100_Y,
                  'Phase Overload Above 100% (Phase-Y)'
                )
              "
            >
              <div class="d-flex justify-content-center align-items-center">
                <div class="text-align-center">
                  <i
                    class="far fa-gauge-circle-bolt phaseY mb-10 font-size-20"
                  ></i>
                  <p class="q-card-value">
                    {{ dashboardDtrCardData?.loaD_100_Y }}
                  </p>
                  <p class="m-0 text-secondary">Phase Y</p>
                </div>
              </div>
            </div>
            <div
              class="q-card q-card-bg-phaseB q-card-box"
              style="cursor: pointer"
              (click)="
                onDashboardDTRStatusClick(
                  dasboardDtrStatus.Load_100_B,
                  dashboardDtrCardData?.loaD_100_B,
                  'Phase Overload Above 100% (Phase-B)'
                )
              "
            >
              <div class="d-flex justify-content-center align-items-center">
                <div class="text-align-center">
                  <i
                    class="far fa-gauge-circle-bolt phaseB mb-10 font-size-20"
                  ></i>
                  <p class="q-card-value">
                    {{ dashboardDtrCardData?.loaD_100_B }}
                  </p>
                  <p class="m-0 text-secondary">Phase B</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <hr />
        <div class="d-flex-row mb-20">
          <div class="d-flex-col-xl-3 d-flex-col-lg-12 d-flex-col">
            <p class="font-size-16 mb-20 line-height-normal mt-10 fw-500">
              Phase Overload <br />
              <span class="text-secondary font-size-14 fw-normal"
                >80% - 100%</span
              >
            </p>
          </div>
          <div
            class="d-flex-col-xl-9 d-flex-col-lg-12 d-flex flex-wrap gap-15 box-1"
          >
            <div
              class="q-card q-card-bg-phaseR q-card-box"
              style="cursor: pointer"
              (click)="
                onDashboardDTRStatusClick(
                  dasboardDtrStatus.Load_80_100_R,
                  dashboardDtrCardData?.loaD_80_100_R,
                  'Phase Overload 80% - 100% (Phase-R)'
                )
              "
            >
              <div class="d-flex justify-content-center align-items-center">
                <div class="text-align-center">
                  <i class="far fa-bolt mb-10 font-size-20 phaseR"></i>
                  <p class="q-card-value font-size-20">
                    {{ dashboardDtrCardData?.loaD_80_100_R }}
                  </p>
                  <p class="m-0 text-secondary">Phase R</p>
                </div>
              </div>
            </div>
            <div
              class="q-card q-card-bg-phaseY q-card-box"
              style="cursor: pointer"
              (click)="
                onDashboardDTRStatusClick(
                  dasboardDtrStatus.Load_80_100_Y,
                  dashboardDtrCardData?.loaD_80_100_Y,
                  'Phase Overload 80% - 100% (Phase-Y)'
                )
              "
            >
              <div class="d-flex justify-content-center align-items-center">
                <div class="text-align-center">
                  <i class="far fa-bolt mb-10 font-size-20 phaseY"></i>
                  <p class="q-card-value">
                    {{ dashboardDtrCardData?.loaD_80_100_Y }}
                  </p>
                  <p class="m-0 text-secondary">Phase Y</p>
                </div>
              </div>
            </div>
            <div
              class="q-card q-card-bg-phaseB q-card-box"
              style="cursor: pointer"
              (click)="
                onDashboardDTRStatusClick(
                  dasboardDtrStatus.Load_80_100_B,
                  dashboardDtrCardData?.loaD_80_100_B,
                  'Phase Overload 80% - 100% (Phase-B)'
                )
              "
            >
              <div class="d-flex justify-content-center align-items-center">
                <div class="text-align-center">
                  <i class="far fa-bolt mb-10 font-size-20 phaseB"></i>
                  <p class="q-card-value">
                    {{ dashboardDtrCardData?.loaD_80_100_B }}
                  </p>
                  <p class="m-0 text-secondary">Phase B</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      class="d-flex-col-xl-5 d-flex-col-lg-12 d-flex-col-md-12 d-flex-col-xs-12 d-flex-col d-flex"
    >
      <div class="q-card border pb-0 mb-20">
        <p class="font-size-16 mb-20 fw-500">Overload Units</p>
        <div class="d-flex-row overload-box">
          <div
            class="d-flex-col-sm-6 d-flex-col-lg-3 d-flex-col-xs-12 d-flex-col d-flex"
          >
            <div
              class="q-card border flex-1 mb-20 d-flex align-items-center text-align-center justify-content-center overload-box-1"
              style="cursor: pointer"
              (click)="
                onDashboardDTRStatusClick(
                  dasboardDtrStatus.Overload100Device,
                  dashboardDtrCardData?.overloaD_ABOVE_100,
                  'Overload Units Above 100%'
                )
              "
            >
              <div class="d-flex justify-content-between align-items-center">
                <div>
                  <i class="far fa-circle-bolt font-size-24 mb-10 phaseR"></i>
                  <p class="q-card-value font-size-20">
                    {{ dashboardDtrCardData?.overloaD_ABOVE_100 }}
                  </p>
                  <p class="m-0 text-secondary">Above 100%</p>
                </div>
              </div>
            </div>
          </div>

          <div
            class="d-flex-col-sm-6 d-flex-col-lg-3 d-flex-col-xs-12 d-flex-col d-flex"
          >
            <div
              class="q-card border flex-1 mb-20 d-flex align-items-center text-align-center justify-content-center overload-box-2"
              style="cursor: pointer"
              (click)="
                onDashboardDTRStatusClick(
                  dasboardDtrStatus.Overload80_100Device,
                  dashboardDtrCardData?.overloaD_80_100,
                  'Overload Units 80% - 100%'
                )
              "
            >
              <div class="d-flex justify-content-between align-items-center">
                <div>
                  <i class="far fa-circle-bolt font-size-24 mb-10 brown"></i>
                  <p class="q-card-value font-size-20">
                    {{ dashboardDtrCardData?.overloaD_80_100 }}
                  </p>
                  <p class="m-0 text-secondary">80% - 100%</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="d-flex-row">
    <div
      class="d-flex-col-xl-7 d-flex-col-md-12 d-flex-col-xs-12 d-flex-col d-flex"
    >
      <div class="q-card border p-0 table-container">
        <table class="dashboard-table">
          <tr>
            <td></td>
            <td>
              <div class="d-flex align-items-center gap-10">
                <span
                  class="q-card-bg-phaseR power-icon d-flex align-items-center justify-content-center"
                  ><i class="far fa-power-off phaseR"></i></span
                ><span>R Phase</span>
              </div>
            </td>
            <td>
              <div class="d-flex align-items-center gap-10">
                <span
                  class="q-card-bg-phaseY power-icon d-flex align-items-center justify-content-center"
                  ><i class="far fa-power-off phaseY"></i></span
                ><span>Y Phase</span>
              </div>
            </td>
            <td>
              <div class="d-flex align-items-center gap-10">
                <span
                  class="q-card-bg-phaseB power-icon d-flex align-items-center justify-content-center"
                  ><i class="far fa-power-off phaseB"></i></span
                ><span>B Phase</span>
              </div>
            </td>
          </tr>
          <tr>
            <td class="td-head">
              <span class="font-size-16 fw-500">Phase Fail / Low Voltage</span>
            </td>
            <td
              class="font-size-20 q-card-value"
              style="cursor: pointer"
              (click)="
                onDashboardDTRStatusClick(
                  dasboardDtrStatus.LowVolatageR,
                  dashboardDtrCardData?.loW_VOLTAGE_R,
                  'Phase Fail / Low Voltage (Phase-R)'
                )
              "
            >
              {{ dashboardDtrCardData?.loW_VOLTAGE_R }}
            </td>
            <td
              class="font-size-20 q-card-value"
              style="cursor: pointer"
              (click)="
                onDashboardDTRStatusClick(
                  dasboardDtrStatus.LowVolatageY,
                  dashboardDtrCardData?.loW_VOLTAGE_Y,
                  'Phase Fail / Low Voltage (Phase-Y)'
                )
              "
            >
              {{ dashboardDtrCardData?.loW_VOLTAGE_Y }}
            </td>
            <td
              class="font-size-20 q-card-value"
              style="cursor: pointer"
              (click)="
                onDashboardDTRStatusClick(
                  dasboardDtrStatus.LowVolatageB,
                  dashboardDtrCardData?.loW_VOLTAGE_B,
                  'Phase Fail / Low Voltage (Phase-B)'
                )
              "
            >
              {{ dashboardDtrCardData?.loW_VOLTAGE_B }}
            </td>
          </tr>
          <tr>
            <td class="td-head">
              <span class="font-size-16 fw-500">High Voltage</span>
            </td>
            <td
              class="font-size-20 q-card-value"
              style="cursor: pointer"
              (click)="
                onDashboardDTRStatusClick(
                  dasboardDtrStatus.HighVolatageR,
                  dashboardDtrCardData?.loW_VOLTAGE_R,
                  'High Voltage (Phase-R)'
                )
              "
            >
              {{ dashboardDtrCardData?.higH_VOLTAGE_R }}
            </td>
            <td
              class="font-size-20 q-card-value"
              style="cursor: pointer"
              (click)="
                onDashboardDTRStatusClick(
                  dasboardDtrStatus.HighVolatageY,
                  dashboardDtrCardData?.loW_VOLTAGE_Y,
                  'High Voltage (Phase-Y)'
                )
              "
            >
              {{ dashboardDtrCardData?.higH_VOLTAGE_Y }}
            </td>
            <td
              class="font-size-20 q-card-value"
              style="cursor: pointer"
              (click)="
                onDashboardDTRStatusClick(
                  dasboardDtrStatus.HighVolatageB,
                  dashboardDtrCardData?.loW_VOLTAGE_B,
                  'High Voltage (Phase-B)'
                )
              "
            >
              {{ dashboardDtrCardData?.higH_VOLTAGE_B }}
            </td>
          </tr>
        </table>
      </div>
    </div>
  </div>
</div>
<div kendoDialogContainer></div>
