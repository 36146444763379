<div *ngIf="!getIsLogged()">
  <div class="login-container">
    <div class="login-card">
      <div class="login-form">
        <div class="login-section">
          <div class="header-contianer">
            <div class="d-flex align-items-center gap-10 mb-20">
              <img
                src="assets/images/logo/login-logo.png"
                alt="logo"
                height="104"
                width="167"
              />
            </div>
            <div>
              <h4 class="header-text">Login to your account</h4>
              <p class="header-desc">Hello! Login with your email</p>
            </div>
          </div>
          <div>
            <form class="k-form k-form-md mt-3" [formGroup]="loginForm">
              <fieldset class="k-form-fieldset">
                <kendo-formfield>
                  <kendo-label
                    [for]="username"
                    text="Mobile/Email"
                    class="d-flex align-items-baseline"
                    labelCssClass="k-form-label"
                    ><span class="required">*</span></kendo-label
                  >
                  <kendo-textbox
                    formControlName="username"
                    placeholder="Mobile/Email"
                    #username
                    required
                    class="input-text"
                  ></kendo-textbox>
                  <kendo-formerror>Mobile/Email is required</kendo-formerror>
                </kendo-formfield>
                <kendo-formfield>
                  <kendo-label
                    labelCssClass="k-form-label"
                    [for]="password"
                    text="Password"
                    class="d-flex align-items-baseline"
                    ><span class="required">*</span></kendo-label
                  >
                  <kendo-textbox
                    placeholder="Password"
                    formControlName="password"
                    #password
                    [type]="passwordVisible ? 'text' : 'password'"
                    required
                    class="input-text"
                  >
                    <ng-template kendoTextBoxSuffixTemplate>
                      <button
                        kendoButton
                        class="password-btn"
                        type="button"
                        (click)="togglePasswordVisibility()"
                      >
                        <i
                          class="far"
                          [ngClass]="
                            passwordVisible ? 'fa-eye' : 'fa-eye-slash'
                          "
                        ></i>
                      </button>
                    </ng-template>
                  </kendo-textbox>
                  <kendo-formerror>Password is required</kendo-formerror>
                </kendo-formfield>
                <div
                  class="d-flex justify-content-between align-items-center flex-wrap gap-5 mt-10"
                >
                  <div *ngIf="false" class="checkbox-container">
                    <input
                      type="checkbox"
                      #remember
                      class="check-box"
                      kendoCheckBox
                    />
                    <kendo-label
                      class="k-checkbox-label"
                      [for]="remember"
                      text="Remember me"
                    ></kendo-label>
                  </div>
                  <div>
                    <a
                      (click)="openForgotPasswordPopup()"
                      class="forget-password"
                      >Forgot password?</a
                    >
                  </div>
                </div>
              </fieldset>
            </form>
          </div>
          <div class="mt-2">
            <button
              kendoButton
              class="d-flex wp-100 mt-10 login-button"
              (click)="loginUser()"
            >
              Login
            </button>
          </div>
          <div class="mt-2">
            Don't have an account?
            <a (click)="showSignupMessage()">Sign Up</a>
          </div>
        </div>
      </div>
      <div class="login-image-container">
        <div>
          <img src="assets/images/ITMS.png" alt="itms" />
        </div>
      </div>
    </div>
    <div class="d-flex justify-content-between gap-10 flex-wrap login-footer">
      <p class="mb-0">
        By Proceeding you agree to the
        <a href="https://istlabs.in/terms-and-conditions.html" target="_blank"
          >Terms of Use</a
        >
        and
        <a href="https://istlabs.in/policy.html" target="_blank"
          >Privacy Policy</a
        >
      </p>
      <img src="assets/images/logo/tagTitle.png" alt="tagTitle" />
    </div>
  </div>
</div>
<div kendoDialogContainer></div>
