import { Component, OnInit } from '@angular/core'
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms'
import {
  BackupDataInExcel,
  CommonState,
  DTRDataDownload,
  DeleteDatabase,
  GetDeviceIdByFeederNotCommon,
  GetNotCommonFeederAreaList,
} from '@app/store'
import { Select, Store } from '@ngxs/store'
import { Observable, Subject } from 'rxjs'
import { NgxSpinnerService } from 'ngx-spinner'
import { DatePipe } from '@angular/common'
import { DownloadOptionEnum } from '@app/enums'
import { ToastrService } from 'ngx-toastr'
import swal from 'sweetalert'
import { NotificationMessage } from '@app/enums/notification'

@Component({
  selector: 'app-dtr-data',
  templateUrl: './dtr-data.component.html',
  styleUrl: './dtr-data.component.scss',
})
export class DtrDataComponent implements OnInit {
  triggerGetUpdatedTime: Subject<any> = new Subject<any>()

  deviceList: { key: string; value: string }[] = []
  deviceListBackUp: { key: string; value: string }[] = []
  feederAreaList: { key: string; value: string }[] = []
  feederAreaListBackup: { key: string; value: string }[] = []

  downloadForm!: FormGroup
  backUpForm!: FormGroup
  downloadOptionList = DownloadOptionEnum

  @Select(CommonState.hasEditPermission)
  hasEditPermission$!: Observable<boolean>

  constructor(
    private store: Store,
    private formBuilder: FormBuilder,
    private spinner: NgxSpinnerService,
    private datePipe: DatePipe,
    private toastr: ToastrService,
  ) {}

  ngOnInit(): void {
    this.setForm()

    setTimeout(() => {
      this.triggerGetUpdatedTime.next(true)
    }, 0)

    this.getFeederList()
  }

  getFeederList(): void {
    this.spinner.show()
    this.store.dispatch(new GetNotCommonFeederAreaList()).subscribe(
      (res) => {
        this.spinner.hide()
        if (res.common.feederAreaListNotCommon.length > 0) {
          this.feederAreaList = res.common.feederAreaListNotCommon
          this.feederAreaListBackup = res.common.feederAreaListNotCommon
          this.store
            .dispatch(
              new GetDeviceIdByFeederNotCommon(this.feederAreaList[0].key),
            )
            .subscribe((x) => {
              this.deviceList = x.common.deviceListNotCommon
              this.deviceListBackUp = x.common.deviceListNotCommon
              this.downloadForm
                .get('feederAreaId')
                ?.setValue(this.feederAreaList[0])
              this.downloadForm.get('deviceId')?.setValue(this.deviceList[0])
              this.backUpForm
                .get('feederAreaIdBackUp')
                ?.setValue(this.feederAreaListBackup[0])
              this.backUpForm
                .get('deviceIdBackUp')
                ?.setValue(this.deviceListBackUp[0])
            })
        }
      },
      (error) => {
        this.spinner.hide()
      },
    )
  }

  onFeederAreaChange(feederAreaId: any) {
    this.store
      .dispatch(new GetDeviceIdByFeederNotCommon(feederAreaId.key))
      .subscribe((x) => {
        this.deviceList = x.common.deviceListNotCommon
        this.downloadForm
          .get('deviceId')
          ?.setValue(this.deviceList.length > 0 ? this.deviceList[0] : null)
      })
  }

  onFeederAreaBackUpChange(feederAreaId: any) {
    this.store
      .dispatch(new GetDeviceIdByFeederNotCommon(feederAreaId.key))
      .subscribe((x) => {
        this.deviceListBackUp = x.common.deviceListNotCommon
      })
  }

  onSubmit() {
    let textMessage = `You want to download DTR Data.`

    swal({
      title: 'Are you sure ?',
      text: textMessage,
      icon: 'warning',
      buttons: {
        cancel: {
          text: 'Cancel',
          visible: true,
          closeModal: true,
        },
        confirm: {
          text: 'Yes',
        },
      },
      dangerMode: true,
    }).then((confirmed) => {
      if (confirmed) {
        if (this.downloadForm.invalid) {
          this.downloadForm.markAllAsTouched()
          return
        }
        this.toastr.info(NotificationMessage.downloadProgressMsg)
        const formData = this.downloadForm.value

        if (formData.startDate > formData.toDate) {
          this.toastr.error(NotificationMessage.startEndDateCompareValidation)
          this.spinner.hide()
          return
        }

        const params = {
          deviceId:
            formData.deviceId === undefined ? null : formData.deviceId.value,
          feederAreaId:
            formData.feederAreaId === undefined
              ? null
              : formData.feederAreaId.value,
          filter: 0,
          sortOrder: true,
          search: null,
          startDate: this.datePipe.transform(
            new Date(formData.startDate),
            "yyyy-MM-ddTHH:mm:ss.SSS'Z'",
          ),
          endDate: this.datePipe.transform(
            new Date(formData.toDate),
            "yyyy-MM-ddT23:59:59.999'Z'",
          ),
        }

        this.store.dispatch(new DTRDataDownload(params)).subscribe(
          (res: any) => {
            const params = {
              title: 'Download',
              text: NotificationMessage.downloadSuccessMsg,
            }
            this.confirmationDialogForDownloadAndBackUp(params)
          },
          (error) => {
            this.toastr.error('Error during onDownload:', error)
          },
        )
      }
    })
  }

  setForm(): void {
    this.downloadForm = new FormGroup({
      feederAreaId: new FormControl(),
      deviceId: new FormControl(),
      startDate: new FormControl('', Validators.required),
      toDate: new FormControl('', Validators.required),
    })

    this.backUpForm = this.formBuilder.group({
      feederAreaIdBackUp: [], // Initialize with default value or leave empty
      deviceIdBackUp: [], // Initialize with default value or leave empty
    })
  }

  downloadOption = [
    { text: 'Dump to Excel' },
    { text: 'Dump to Sql' },
    { text: 'Delete the Database' },
  ]

  onDownloadOptionSelected(event: any) {
    let textMessage = ''

    if (event.text === 'Dump to Excel') {
      textMessage = `You want to Dump Data to Excel.`
    } else if (event.text === 'Dump to Sql') {
      textMessage = `You want to Dump Data to Sql.`
    } else if (event.text === 'Delete the Database') {
      textMessage = `You want to Delete the Database ${this.backUpForm.value.deviceIdBackUp.value}.`
    }

    swal({
      title: 'Are you sure ?',
      text: textMessage,
      icon: 'warning',
      buttons: {
        cancel: {
          text: 'Cancel',
          visible: true,
          closeModal: true,
        },
        confirm: {
          text: 'Yes',
        },
      },
      dangerMode: true,
    }).then((confirmed) => {
      if (confirmed) {
        if (event.text === 'Dump to Excel') {
          this.onBackup(DownloadOptionEnum['Dump to Excel'])
        } else if (event.text === 'Dump to Sql') {
          this.onBackup(DownloadOptionEnum['Dump to Sql'])
        } else if (event.text === 'Delete the Database') {
          this.onDeleteDatabase()
        }
      }
    })
  }

  onBackup(formateType: any) {
    const deviceId = this.backUpForm.value.deviceIdBackUp.value
    if (!deviceId) {
      return
    }
    if (formateType === DownloadOptionEnum['Dump to Excel']) {
      this.toastr.info(NotificationMessage.dumpToExcelProgressMsg)
    } else {
      this.toastr.info(NotificationMessage.dumpToSqlProgressMsg)
    }

    this.store
      .dispatch(new BackupDataInExcel(deviceId, formateType))
      .subscribe(() => {
        const params = {
          title: 'Backup',
          text:
            formateType === DownloadOptionEnum['Dump to Excel']
              ? NotificationMessage.dumpToExcelSuccessMsg
              : NotificationMessage.dumpToSqlSuccessMsg,
        }
        this.confirmationDialogForDownloadAndBackUp(params)
      })
  }

  onDeleteDatabase() {
    const deviceId = this.backUpForm.value.deviceIdBackUp.value
    if (!deviceId) {
      return
    }
    this.toastr.info(NotificationMessage.databaseDeleteProgressMsg)

    this.store.dispatch(new DeleteDatabase(deviceId)).subscribe(() => {
      const params = {
        title: 'Database',
        text: NotificationMessage.databaseDeleteSuccessMsg,
      }
      this.confirmationDialogForDownloadAndBackUp(params)
    })
  }

  onTabSelect(event: any): void {}

  confirmationDialogForDownloadAndBackUp(params: any) {
    swal({
      title: params.title,
      text: params.text,
      icon: 'success',
      buttons: {
        confirm: {
          text: 'Ok',
        },
      },
      dangerMode: true,
    })
  }
}
