<div class="q-content" *ngIf="!isAddMode">
  <div *ngIf="!isAddMode" class="title">{{ headerText }}</div>
  <div class="q-content-bg">
    <div>
      <form [formGroup]="deviceForm" class="k-form k-form-md">
        <div *ngIf="!isAddMode">
          <div class="d-flex gap-15 flex-wrap mb-20">
            <kendo-formfield class="wmax-280 mt-0">
              <kendo-label
                [for]="deviceId"
                text="Device Id"
                labelCssClass="k-form-label"
                ><span class="required">*</span></kendo-label
              >
              <div class="curser-not-allowd">
                <kendo-textbox
                  formControlName="deviceId"
                  #deviceId
                  [readonly]="true"
                  class="disabled"
                ></kendo-textbox>
              </div>
            </kendo-formfield>
            <kendo-formfield class="wmax-280 mt-0">
              <kendo-label
                [for]="deviceName"
                text="Device Name"
                labelCssClass="k-form-label"
              ></kendo-label>
              <kendo-textbox
                formControlName="deviceName"
                #deviceName
                appNoSpacesOrSpecialChars
              ></kendo-textbox>
            </kendo-formfield>
          </div>
          <div class="d-flex gap-15 flex-wrap mb-20">
            <kendo-formfield class="wmax-280 mt-0">
              <kendo-label
                [for]="ctRatio"
                text="CT Ratio"
                labelCssClass="k-form-label"
              ></kendo-label>
              <kendo-textbox
                formControlName="ctRatio"
                #ctRatio
                (keypress)="commonService.numberOnly($event)"
              ></kendo-textbox>
            </kendo-formfield>
            <kendo-formfield class="wmax-280 mt-0">
              <kendo-label
                [for]="ratedKva"
                text="Rated KVA"
                labelCssClass="k-form-label"
              ></kendo-label>
              <kendo-textbox
                formControlName="ratedKva"
                (keypress)="commonService.numberOnly($event)"
                #ratedKva
              ></kendo-textbox>
            </kendo-formfield>
          </div>
          <kendo-tabstrip>
            <kendo-tabstrip-tab title="GPS" [selected]="true">
              <ng-template kendoTabContent>
                <div class="tabs-content-device">
                  <div class="k-form k-form-md">
                    <kendo-formfield class="mt-0 wmax-280 mb-20">
                      <kendo-label
                        [for]="latlong"
                        text="Lat & long"
                        labelCssClass="k-form-label"
                      ></kendo-label>
                      <kendo-textbox
                        formControlName="latLong"
                        #latlong
                        appNoSpacesOrSpecialChars
                      ></kendo-textbox>
                    </kendo-formfield>

                    <div>
                      <kendo-label
                        text="Toggle Switch"
                        [for]="staticGPS"
                        text="Static GPS"
                      >
                      </kendo-label>
                      <kendo-switch
                        class="ml-10"
                        formControlName="staticGPS"
                        #staticGPS
                      ></kendo-switch>
                    </div>
                  </div>
                </div>
              </ng-template>
            </kendo-tabstrip-tab>
            <kendo-tabstrip-tab title="Address">
              <ng-template kendoTabContent>
                <div class="k-form k-form-md">
                  <div class="d-flex-row mr-10">
                    <kendo-formfield
                      class="d-flex-col-lg-4 d-flex-col-md-6 d-flex-col-xs-12 d-flex-col mb-20 mt-0"
                    >
                      <kendo-label
                        [for]="StreetLine"
                        text="Street/Line"
                        labelCssClass="k-form-label"
                      ></kendo-label>
                      <kendo-textbox
                        formControlName="street"
                        #StreetLine
                        appNoSpacesOrSpecialChars
                      ></kendo-textbox>
                    </kendo-formfield>
                    <kendo-formfield
                      class="d-flex-col-lg-4 d-flex-col-md-6 d-flex-col-xs-12 d-flex-col mb-20 mt-0"
                    >
                      <kendo-label
                        [for]="area"
                        text="Area"
                        labelCssClass="k-form-label"
                      ></kendo-label>
                      <kendo-textbox
                        formControlName="area"
                        #area
                        appNoSpacesOrSpecialChars
                      ></kendo-textbox>
                    </kendo-formfield>
                  </div>
                  <div class="d-flex-row mr-10">
                    <kendo-formfield
                      class="d-flex-col-lg-4 d-flex-col-md-6 d-flex-col-xs-12 d-flex-col mb-20 mt-0"
                    >
                      <kendo-label
                        [for]="CityTown"
                        text="City/Town"
                        labelCssClass="k-form-label"
                      ></kendo-label>
                      <kendo-textbox
                        formControlName="city"
                        #CityTown
                        appNoSpacesOrSpecialChars
                      ></kendo-textbox>
                    </kendo-formfield>
                    <kendo-formfield
                      class="d-flex-col-lg-4 d-flex-col-md-6 d-flex-col-xs-12 d-flex-col mb-20 mt-0"
                    >
                      <kendo-label
                        [for]="district"
                        text="District"
                        labelCssClass="k-form-label"
                      ></kendo-label>
                      <kendo-textbox
                        formControlName="district"
                        #district
                        appNoSpacesOrSpecialChars
                      ></kendo-textbox>
                    </kendo-formfield>
                  </div>
                  <div class="d-flex-row mr-10">
                    <kendo-formfield
                      class="d-flex-col-lg-4 d-flex-col-md-6 d-flex-col-xs-12 d-flex-col mb-20 mt-0"
                    >
                      <kendo-label
                        [for]="state"
                        text="State"
                        labelCssClass="k-form-label"
                      ></kendo-label>
                      <kendo-textbox
                        formControlName="state"
                        #state
                        appNoSpacesOrSpecialChars
                      ></kendo-textbox>
                    </kendo-formfield>
                    <kendo-formfield
                      class="d-flex-col-lg-4 d-flex-col-md-6 d-flex-col-xs-12 d-flex-col mb-20 mt-0"
                    >
                      <kendo-label
                        [for]="pincode"
                        text="Pin Code"
                        labelCssClass="k-form-label"
                      ></kendo-label>
                      <kendo-textbox
                        formControlName="pincode"
                        #pincode
                      ></kendo-textbox>
                    </kendo-formfield>
                  </div>
                  <div class="d-flex-row mr-10" *ngIf="false">
                    <kendo-formfield
                      class="d-flex-col-lg-4 d-flex-col-md-6 d-flex-col-xs-12 d-flex-col mb-20 mt-0"
                    >
                      <kendo-label
                        [for]="country"
                        text="Country"
                        labelCssClass="k-form-label"
                      ></kendo-label>
                      <kendo-textbox
                        formControlName="country"
                        #country
                      ></kendo-textbox>
                    </kendo-formfield>
                  </div>
                </div>
              </ng-template>
            </kendo-tabstrip-tab>
            <kendo-tabstrip-tab title="Upload Images">
              <ng-template kendoTabContent>
                <div class="tabs-content-device">
                  <div class="file-upload-area">
                    <label for="fileInput" class="file-upload">
                      <span class="upload-icon-container"
                        ><i class="far fa-arrow-up-from-bracket"></i
                      ></span>
                      <p class="font-size-14 mb-0">
                        <span class="primary-text fw-500">Choose file</span> to
                        upload
                      </p>
                    </label>
                    <input
                      type="file"
                      id="fileInput"
                      #fileInput
                      (change)="onSelectFile($event)"
                    />
                  </div>
                  <div class="q-card pt-0 pb-0 image-url-conatiner">
                    <div *ngIf="deviceImageList.length">
                      <div
                        *ngFor="let data of deviceImageList; let i = index"
                        class="image-upload-area"
                      >
                        <div class="d-flex align-items-center gap-15">
                          <img
                            [src]="data.base64Value"
                            alt=""
                            (click)="onImageOpen(i)"
                          />
                          <div class="font-size-14">{{ data.fileName }}</div>
                        </div>
                        <div class="d-flex gap-10">
                          <a
                            title="dowload"
                            (click)="onDownloadImage(data.fileName)"
                          >
                            <i
                              class="far fa-download text-secondary font-size-16 mt-5"
                            ></i>
                          </a>
                          <a
                            title="delete"
                            (click)="onDeleteImageFromListFile(i)"
                          >
                            <i
                              class="far fa-circle-xmark text-secondary font-size-16 mt-5"
                            ></i>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </ng-template>
            </kendo-tabstrip-tab>
          </kendo-tabstrip>
        </div>
      </form>
    </div>
    <div class="footer-button-container d-flex gap-15">
      <button kendoButton (click)="onCancel()" fillMode="outline">
        Cancel
      </button>
      <div
        [ngClass]="{ 'curser-not-allowd': deviceForm.invalid }"
        class="display-inline flex-0"
      >
        <button
          kendoButton
          (click)="onDeviceSubmit()"
          [disabled]="deviceForm.invalid"
        >
          {{ submitButtonText }}
        </button>
      </div>
    </div>
  </div>
</div>

<div *ngIf="isAddMode">
  <div class="add-device">
    <form [formGroup]="deviceForm" class="k-form k-form-md">
      <div *ngIf="isAddMode">
        <kendo-formfield class="mt-0">
          <kendo-label
            [for]="deviceId"
            text="Device Id"
            labelCssClass="k-form-label"
            ><span class="required">*</span></kendo-label
          >
          <kendo-textbox
            formControlName="deviceId"
            #deviceId
            appNoSpacesOrSpecialChars
          ></kendo-textbox>
        </kendo-formfield>
        <kendo-formfield>
          <kendo-label
            [for]="deviceName"
            text="Device Name"
            labelCssClass="k-form-label"
          ></kendo-label>
          <kendo-textbox
            formControlName="deviceName"
            appNoSpacesOrSpecialChars
            #deviceName
          ></kendo-textbox>
        </kendo-formfield>
      </div>
    </form>
  </div>
</div>

<kendo-dialog-actions [ngClass]="{ 'display-none': !isAddMode }">
  <div class="d-flex gap-10 justify-content-end">
    <button kendoButton (click)="onCancel()" fillMode="outline">Cancel</button>
    <div
      [ngClass]="{ 'curser-not-allowd': deviceForm.invalid }"
      class="display-inline flex-0"
    >
      <button
        kendoButton
        (click)="
          +this.currentUserDetails.RoleId === rolesList.SuperAdmin
            ? onDeviceAdd()
            : onDeviceSubmit()
        "
        [disabled]="deviceForm.invalid"
      >
        {{ submitButtonText }}
      </button>
    </div>
  </div>
</kendo-dialog-actions>

<!-- <div kendoDialogContainer></div> -->
