import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'

import { OfficeSettingRoutingModule } from './office-setting-routing.module'
import { SharedModule } from '@shared//shared.module'
import { PipeModule } from '@app/pipe/pipe.module'
import { ChangesComponent, CommonsModule } from '@app/index'
import { OfficeSettingListComponent } from './office-setting-list/office-setting-list.component'
import { DeviceIdChangeComponent } from './changes/device-id-change/device-id-change.component'
import { EnergyComponent } from './changes/energy/energy.component'
import { UnbalancedLoadComponent } from './changes/unbalanced-load/unbalanced-load.component'
import { WifiComponent } from './changes/wifi/wifi.component'
import { AngleBelowComponent } from './changes/angle-below/angle-below.component'
import { AngleAboveComponent } from './changes/angle-above/angle-above.component'
import { DeviceSerialIdComponent } from './changes/device-serial-id/device-serial-id.component'
import { LoadedSettingDialogComponent } from './loaded-setting-dialog/loaded-setting-dialog.component'

@NgModule({
  declarations: [
    OfficeSettingListComponent,
    ChangesComponent,
    DeviceIdChangeComponent,
    EnergyComponent,
    UnbalancedLoadComponent,
    WifiComponent,
    AngleBelowComponent,
    AngleAboveComponent,
    DeviceSerialIdComponent,
    LoadedSettingDialogComponent,
  ],
  imports: [
    CommonModule,
    OfficeSettingRoutingModule,
    SharedModule,
    PipeModule,
    CommonsModule,
  ],
})
export class OfficeSettingModule {}
