import { DatePipe } from '@angular/common'
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core'
import { MenuList } from '@app/enums'
import { NotificationMessage } from '@app/enums/notification'
import {
  CommonState,
  GetDeviceIdByFeeder,
  GetDeviceIdByFeederAll,
  GetFeederAreaList,
  SetDeviceId,
  SetFeederAreaId,
} from '@app/store'
import { Select, Store } from '@ngxs/store'
import { SelectionRange } from '@progress/kendo-angular-dateinputs'
import { Moment } from 'moment'
import { NgxSpinnerService } from 'ngx-spinner'
import { ToastrService } from 'ngx-toastr'
import {
  EMPTY,
  Observable,
  catchError,
  finalize,
  map,
  switchMap,
  tap,
} from 'rxjs'

@Component({
  selector: 'app-common-header',
  templateUrl: './common-header.component.html',
  styleUrl: './common-header.component.scss',
})
export class CommonHeaderComponent implements OnInit {
  feederAreaList: [] = []
  deviceList: any[] = []

  feederId: any
  deviceId: any
  deviceIdAll: any
  date: any
  selectedDateRange: { startDate: Moment; endDate: Moment } | undefined

  dateRange: {
    startDate: Date | null
    endDate: Date | null
  } = {
    startDate: null,
    endDate: null,
  }

  @Output()
  readonly triggerDataFromHeader = new EventEmitter<any>()

  @Input()
  showAreaFilter: boolean = false

  @Input()
  showDateFilter: boolean = true

  @Input()
  showDateRange: boolean = false

  isDateRangeInitialized = false

  @Select(CommonState.getFeederAreaList)
  feederAreaList$: Observable<any> | undefined

  @Select(CommonState.getDeviceList)
  deviceList$: Observable<any> | undefined

  @Select(CommonState.getDeviceListAll)
  deviceListAll$: Observable<any[]> | undefined

  @Select(CommonState.getFeederAreaId)
  feederAreaId$: Observable<any> | undefined

  selectedDevice: Array<{ deviceName: string; deviceId: string }> | any
  tempDeviceList: any[] = []
  public dataResultDevice:
    | Array<{
        deviceName: string
        deviceId: string
      }>
    | any

  @Select(CommonState.moduleId)
  moduleId$!: Observable<number>

  modules = MenuList
  moduleId: number = 0
  deviceIds: any[] = []
  constructor(
    public store: Store,
    private toastr: ToastrService,
    private datePipe: DatePipe,
    private spinner: NgxSpinnerService,
  ) {}

  ngOnInit(): void {
    this.getData()
  }

  ngAfterViewInit() {
    this.isDateRangeInitialized = true
  }

  onDateRangeChange(event: any): void {
    if (!this.isDateRangeInitialized) return

    const { startDate, endDate } = event || {}

    if (startDate) {
      this.dateRange.startDate = startDate.format('YYYY-MM-DD')
      this.dateRange.endDate = endDate?.format('YYYY-MM-DD')
    } else {
      this.resetDateRange()
    }
    this.dataChangeFromHeader()
  }

  private resetDateRange(): void {
    this.dateRange.startDate = null
    this.dateRange.endDate = null
    this.selectedDateRange = undefined
  }

  public get formattedValue(): string {
    if (
      !(this.dateRange && this.dateRange.startDate && this.dateRange.endDate)
    ) {
      return ''
    }

    return (
      this.datePipe.transform(this.dateRange.startDate, 'dd-MM-yyyy') +
      ' - ' +
      this.datePipe.transform(this.dateRange.endDate, 'dd-MM-yyyy')
    )
  }

  getData(): void {
    this.feederId = this.store.selectSnapshot(CommonState.getFeederAreaId)
    this.deviceId = this.store.selectSnapshot(CommonState.getDeviceId)
    const moduleId = this.store.selectSnapshot(CommonState.moduleId)

    if (
      this.feederId === undefined ||
      this.feederId === null ||
      this.feederId === ''
    ) {
      this.store
        .dispatch(new GetFeederAreaList())
        .pipe(
          switchMap((res: any) => {
            if (
              moduleId === MenuList['Meter Data'] ||
              moduleId === MenuList['Data Report']
            ) {
              return this.store.dispatch(new GetDeviceIdByFeederAll())
            } else {
              return this.store.dispatch(new GetDeviceIdByFeeder())
            }
          }),
        )
        .subscribe((res) => {
          if (res.common.feederAreaList.length > 0) {
            this.feederId = { key: 'All', value: 'All' }
          }
          this.dataChangeFromHeader()
        })
    } else {
      if (
        moduleId !== MenuList['Meter Data'] &&
        moduleId !== MenuList['Data Report']
      ) {
        this.deviceList$?.subscribe((devices: any) => {
          this.deviceList = devices
          this.tempDeviceList = devices
          this.selectedDevice = [
            {
              value: devices[0].value,
              key: devices[0].key,
            },
          ]
          this.deviceIds = this.selectedDevice.map(
            (element: any) => element.value,
          )
          this.dataChangeFromHeader()
        })
      } else {
        this.deviceListAll$?.subscribe((devices: any) => {
          if (devices !== undefined && devices !== null) {
            this.deviceIdAll = devices[0]
          }
        })
      }
    }

    this.moduleId$.subscribe((res) => {
      this.moduleId = res
    })
  }

  onFeederAreaChange(feederAreaId: any) {
    this.spinner.show()
    this.store
      .dispatch(new SetFeederAreaId(feederAreaId))
      .pipe(
        switchMap(() => {
          const moduleId = this.store.selectSnapshot(CommonState.moduleId)

          if (
            moduleId === MenuList['Meter Data'] ||
            moduleId === MenuList['Data Report']
          ) {
            return this.store.dispatch(new GetDeviceIdByFeederAll())
          } else {
            return this.store.dispatch(new GetDeviceIdByFeeder())
          }
        }),
        tap(() => {
          this.deviceId = this.store.selectSnapshot(CommonState.getDeviceId)
          this.deviceIdAll = this.store.selectSnapshot(
            CommonState.getDeviceIdAll,
          )
          this.dataChangeFromHeader()
        }),
        finalize(() => {
          this.spinner.hide()
        }),
        catchError((error) => {
          this.spinner.hide()
          return EMPTY
        }),
      )
      .subscribe()
  }

  onDateTimeChange(date: any) {
    this.date = date
    this.dataChangeFromHeader()
  }

  tagMapper = (tags: any[]) => {
    if (tags.length > 2) {
      return [
        ...tags.slice(0, 2),
        {
          key: `+ ${tags.length - 2}`,
          value: null,
        },
      ]
    } else {
      return tags
    }
  }

  onMutliDeviceChange(device: any) {
    this.deviceIds = device.map((element: any) => element.value)
    this.dataChangeFromHeader()
  }

  deviceFilterChange(searchTerm: any) {
    const contains = (data: string) => (item: { key: string; value: string }) =>
      item.value.toLowerCase().includes(data.toLowerCase())
    this.deviceList = this.tempDeviceList.filter(contains(searchTerm))
  }

  onDeviceChange(deviceId: any) {
    this.deviceId = deviceId
    this.store.dispatch(new SetDeviceId(deviceId))
    this.dataChangeFromHeader()
  }

  onDeviceChangeAll(deviceId: any) {
    this.deviceIdAll = deviceId
    this.dataChangeFromHeader()
  }

  dataChangeFromHeader(): void {
    const moduleId = this.store.selectSnapshot(CommonState.moduleId)
    if (
      (this.deviceId !== undefined && this.deviceId !== null) ||
      this.deviceIds.length ||
      this.showAreaFilter
    ) {
      let params = {}
      if (!this.showDateRange) {
        params = {
          feederAreaId:
            this.feederId !== null && this.feederId !== undefined
              ? this.feederId.value
              : 'All',
          deviceId:
            moduleId === MenuList['Meter Data'] ||
            moduleId === MenuList['Data Report']
              ? this.deviceIdAll?.value
              : this.deviceId?.value,
          date: this.date,
          startDate: this.datePipe.transform(this.date, 'yyyy-MM-dd'),
          endDate: this.datePipe.transform(this.date, 'yyyy-MM-dd'),
          deviceIds: this.deviceIds.reverse(),
        }
      } else {
        params = {
          feederAreaId:
            this.feederId !== null && this.feederId !== undefined
              ? this.feederId.value
              : 'All',
          deviceId:
            moduleId === MenuList['Meter Data'] ||
            moduleId === MenuList['Data Report']
              ? this.deviceIdAll?.value
              : this.deviceId?.value,
          date: this.date,
          startDate: this.datePipe.transform(
            this.dateRange?.startDate,
            'yyyy-MM-dd',
          ),
          endDate: this.datePipe.transform(
            this.dateRange?.endDate,
            'yyyy-MM-dd',
          ),
          deviceIds: this.deviceIds.reverse(),
        }
      }

      this.triggerDataFromHeader.emit(params)
    } else {
      this.toastr.error(NotificationMessage.noDeviceErrorMsg)
    }
  }
}
