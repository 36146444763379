export enum OfficeTypeListEmun {
  'Device Reports' = 1,
  'Review Settings' = 2,
  'Loaded Settings' = 3,
  'SIM Module Off Report' = 4,
  'Simcom Status' = 5,
  'System Status' = 6,
}

export enum OfficeTypeListEnumInfo {
  'Device Reports' = 'This page shows the history of Updated Voltage threshold settings.',
  'Review Settings' = 'For Office Use Only.',
  'SIM Module Off Report' = 'The table display the SIMCOM Module Communication Fail Time.',
}

export enum ModuleHistoryModuleList {
  'Roles' = 35,
  'Hierarchical Permission' = 41,
  'Menu Permission' = 36,
  'Column Permission' = 38,
}
